import * as type from '../types';

export function loginUserByAddressSuccess(payload) {
    return {
        type: type.LOGIN_USER_BY_ADDRESS_SUCCESS,
        payload,
    }
}

export function loginUserByAddress(payload) {
    return {
        type: type.LOGIN_USER_BY_ADDRESS,
        payload,
    }
}

export function loginUserByAddressFailed(payload) {
    return {
        type: type.LOGIN_USER_BY_ADDRESS_FAILED,
        payload,
    }
}

export function loginUserByUserNameSuccess(payload) {
    return {
        type: type.LOGIN_USER_BY_USERNAME_SUCCESS,
        payload,
    }
}

export function loginUserByUserName(payload) {
    return {
        type: type.LOGIN_USER_BY_USERNAME,
        payload,
    }
}

export function loginUserByUserNameFailed(payload) {
    return {
        type: type.LOGIN_USER_BY_USERNAME_FAILED,
        payload,
    }
}

export function getUserDetailByAddressSuccess(payload) {
    return {
        type: type.GET_USER_DETAIL_BY_ADDRESS_SUCCESS,
        payload,
    }
}

export function getUserDetailByAddress(payload) {
    return {
        type: type.GET_USER_DETAIL_BY_ADDRESS,
        payload,
    }
}

export function getUserDetailByAddressFailed(payload) {
    return {
        type: type.GET_USER_DETAIL_BY_ADDRESS_FAILED,
        payload,
    }
}

export function getTokenByUserSuccess(payload) {
    return {
        type: type.GET_TOKEN_BY_USER_SUCCESS,
        payload,
    }
}

export function getTokenByUser(payload) {
    return {
        type: type.GET_TOKEN_BY_USER,
        payload,
    }
}

export function getTokenByUserFailed(payload) {
    return {
        type: type.GET_TOKEN_BY_USER_FAILED,
        payload,
    }
}

export function updateProfileSuccess(payload) {
    return {
        type: type.UPDATE_PROFILE_SUCCESS,
        payload,
    }
}

export function updateProfile(payload) {
    return {
        type: type.UPDATE_PROFILE,
        payload,
    }
}

export function updateProfileFailed(payload) {
    return {
        type: type.UPDATE_PROFILE_FAILED,
        payload,
    }
}

export function uploadProfileImageSuccess(payload) {
    return {
        type: type.UPLOAD_PROFILE_IMAGE_SUCCESS,
        payload,
    }
}

export function uploadProfileImage(payload) {
    return {
        type: type.UPLOAD_PROFILE_IMAGE,
        payload,
    }
}

export function uploadProfileImageFailed(payload) {
    return {
        type: type.UPLOAD_PROFILE_IMAGE_FAILED,
        payload,
    }
}

export function uploadBannerImageSuccess(payload) {
    return {
        type: type.UPLOAD_BANNER_IMAGE_SUCCESS,
        payload,
    }
}

export function uploadBannerImage(payload) {
    return {
        type: type.UPLOAD_BANNER_IMAGE,
        payload,
    }
}

export function uploadBannerImageFailed(payload) {
    return {
        type: type.UPLOAD_BANNER_IMAGE_FAILED,
        payload,
    }
}

export function userLogout(payload) {
    return {
        type: type.USER_LOGOUT,
        payload,
    }
}

export function resetStatus(payload) {
    return {
        type: type.RESET_STORE_STATUS,
        payload,
    }
}