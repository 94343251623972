import React, { useEffect,useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Chamagotchi from './pages/Chamagotchi/Chamagotchi';
import DesignYourPet from './pages/DesignYourPet/DesignYourPet';
import Home from './pages/Home/Home'
import Signup from './pages/Signup/Signup';
import OldHome from './pages/Old_home/Old_home';
import Dashboard from './pages/Dashboard/Dashboard';
import Profile from './pages/Profile/Profile';
import EditProfile from './pages/EditProfile/EditProfile';
import Navbar from './components/Navbar/Navbar';
import ChamagotchiDetails from './pages/ChamagotchiDetails/ChamagotchiDetails';
import Faq from './pages/Faq/Faq';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

import {nftContract, signer, provider}  from './gochitvenderWeb3';
import {airContract} from './airdropContract';
import { ethers } from 'ethers';
import ChamagotchiLeaderBoard from './pages/ChamagotchiLeaderBoard/ChamagotchiLeaderBoard';
import OxChams from './pages/OxChams/OxChams';

const {utils, BigNumber} = require('ethers');

function App() {
  const [accountNo, setAccountNo] = useState('');
  const [userId, setUserId] = useState();
  const [activePage, setActivePage] = useState('');
  const [chamagotchiIdForEdit, setChamagotchiIdForEdit] = useState('');
  const [accountAddForEditChamagotchi, setAccountAddForEditChamagotchi] = useState('');
  const [isAGuestUser, setIsAGuestUser] = useState(false);
  const [airdropTxnId, setAirdropTxnId] = useState('');

  const [accountAdd, setAccountAdd] = useState('');

 
  const loginByUserNameStatus = useSelector(state => state.users.loginByUserNameStatus);

  const mintNftHandler = async () => {
    try {
        const accounts = await window.ethereum.enable();
        const amount = 1;
        const balance = BigNumber.from('1');
        console.log(utils.formatEther(balance));
        //let nftTxn = await nftContract.activateMint({from :'0xDF11D3a21DCAc501282d5376e0819f9cc5BD7200'});
        //const estimation = await nftContract.estimateGas.purchase();
        //console.log("Estimated gas is ==============",estimation);
        let nftTxn = await nftContract.purchase({
          from:signer.address,
          value: balance
        });
        console.log("Mining... please wait");
        console.log("txnObject : ", nftTxn)
        console.log(`Discount price of token is ============/${nftTxn.hash}`);
    } catch (err) {
      console.log(err);
    }
  }

  const mintBalanceHandler = async () => {
    try {
        //await addPolygonTestnetNetwork();
        console.log("Initialize payment");
        let nftTxn = await airContract.purchase();
        console.log("Balance f... please wait");
        console.log(`Balance of given address is  ============/${nftTxn.hash}`);
    } catch (err) {
      console.log(err);
    }
  }
  
  const mintNftButton = () => {
    return (
      <button onClick={mintNftHandler} className='cta-button mint-nft-button'>
        PURCHASE NFT
      </button>
    )
  }

  const mintBalanceButton = () => {
    return (
      <button onClick={mintBalanceHandler} className='cta-button mint-nft-button'>
        NFT BALANCE
      </button>
    )
  }

  async function addPolygonTestnetNetwork(){
    const { ethereum } = window;
    try {
        await ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x13881' }], // Hexadecimal version of 80001, prefixed with 0x
        });
    } catch (error) {
        if (error.code === 4902) {
            try {
                await ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [{ 
                        chainId: '0x13881', // Hexadecimal version of 80001, prefixed with 0x
                        chainName: "Mumbai Testnet",
                        nativeCurrency: {
                            name: "MATIC",
                            symbol: "MATIC",
                            decimals: 18,
                        },
                        rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
                        blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
                        iconUrls: [""],
                
                    }],
                });
            } catch (addError){
                console.log('Did not add network');
            }
        }
    }
}

  return (
    <div className="App">
      <Navbar isSignedUp={localStorage.getItem("chameleon_access_token")} accountNo={accountNo} setAccountNo={setAccountNo} setUserId={setUserId} activePage={activePage} setActivePage={setActivePage} setIsAGuestUser={setIsAGuestUser}/>
      <ToastContainer closeButton={false}/>
      <Switch>
          <Route exact path="/0xChams" render={() =>  <OxChams setActivePage={setActivePage} />} />
          <Route exact path="/" render={() =>   <OxChams setActivePage={setActivePage} />} />
          <Route exact path="/chamagotchi" render={() => (isAGuestUser || localStorage.getItem("chameleon_access_token")) ? <Chamagotchi setActivePage={setActivePage} /> : <Redirect to="/signup" setActivePage={setActivePage}/>} />
        {/*<Route exact path="/" render={() => (isAGuestUser || localStorage.getItem("chameleon_access_token")) ? <Home setActivePage={setActivePage}/> : <Redirect to="/signup" setActivePage={setActivePage}/>} />*/}
        <Route exact path="/signup" render={() => <Signup accountNo={accountNo} setAccountNo={setAccountNo} userId={userId} setUserId={setUserId} setActivePage={setActivePage} setIsAGuestUser={setIsAGuestUser}/>} />
        <Route exact path="/dashboard" render={() => (localStorage.getItem("chameleon_access_token")) ? <Dashboard  accountNo={accountNo} setAccountNo={setAccountNo} userId={userId} setUserId={setUserId} setActivePage={setActivePage} setChamagotchiIdForEdit={setChamagotchiIdForEdit} setAccountAdd={setAccountAdd} setAirdropTxnId={setAirdropTxnId}/> : <Redirect to="/signup" setActivePage={setActivePage}/>} />
        <Route exact path="/profile" render={() => (localStorage.getItem("chameleon_access_token")) ? <Profile accountNo={accountNo} setAccountNo={setAccountNo} setUserId={setUserId} setActivePage={setActivePage}/> : <Redirect to="/signup" setActivePage={setActivePage}/>} />
        <Route exact path="/edit-profile" render={() => (localStorage.getItem("chameleon_access_token")) ? <EditProfile accountNo={accountNo} setAccountNo={setAccountNo} userId={userId} setUserId={setUserId} setActivePage={setActivePage} /> : <Redirect to="/signup" setActivePage={setActivePage}/>} />
        <Route exact path="/design-your-pet" render={() => (localStorage.getItem("chameleon_access_token")) ? (chamagotchiIdForEdit) ? <DesignYourPet accountNo={accountAdd} setActivePage={setActivePage} chamagotchiIdForEdit={chamagotchiIdForEdit} setChamagotchiIdForEdit={setChamagotchiIdForEdit} airdropTxnId={airdropTxnId} loginByUserNameStatus={loginByUserNameStatus}/> : <Redirect to="/dashboard"  accountNo={accountNo} setAccountNo={setAccountNo} userId={userId} setUserId={setUserId} setActivePage={setActivePage} setChamagotchiIdForEdit={setChamagotchiIdForEdit} setAirdropTxnId={setAirdropTxnId}/> : <Redirect to="/signup" setActivePage={setActivePage}/>} />
        <Route exact path="/faq" render={() => (isAGuestUser || localStorage.getItem("chameleon_access_token")) ? <Faq setActivePage={setActivePage} /> : <Redirect to="/signup" setActivePage={setActivePage}/>} />
        <Route exact path="/chamagotchi-details/:chamagotchiId" render={() => (localStorage.getItem("chameleon_access_token")) ? <ChamagotchiDetails /> : <Redirect to="/signup" setActivePage={setActivePage}/>} />
        <Route exact path="/leaderboard" render={() => (isAGuestUser || localStorage.getItem("chameleon_access_token")) ? <ChamagotchiLeaderBoard setActivePage={setActivePage} /> : <Redirect to="/signup" setActivePage={setActivePage}/>} />
        {/* <Route exact path="/old-home" render={() => (localStorage.getItem("chameleon_access_token")) ? <OldHome /> : <Redirect to="/signup" setActivePage={setActivePage}/>} /> */}
      </Switch>
       {/* <h1>Scrappy Squirrels Tutorial</h1>
      <div>
        {mintNftButton()}
      </div>
      <div>
        {mintBalanceButton()}
      </div>*/}
    </div>
  );
}

export default App;
