import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getChamagotchiDetailsById, resetChamagotchi } from '../../redux/actions/chamagotchi';
import { FaChevronLeft, FaRegCopy } from "react-icons/fa";
import { FadeLoader }  from 'react-spinners';
import Footer from '../../components/Footer/Footer';
import ChamagotchiDetailsTable from '../../components/ChamagotchiDetailsTable/ChamagotchiDetailsTable';
import './ChamagotchiDetails.css';
import { toast } from 'react-toastify';

function ChamagotchiDetails() {

    const { chamagotchiId } = useParams();   

    const history = useHistory();

    const dispatch = useDispatch();
    const ChamagotchiDetailsById = useSelector(state => state.chamagotchi.ChamagotchiDetailsById);
    const loading = useSelector(state => state.chamagotchi.loading);

    useEffect(() => {
        dispatch(getChamagotchiDetailsById(chamagotchiId))
    }, [chamagotchiId]);

    useEffect(() => {
        dispatch(getChamagotchiDetailsById(chamagotchiId))
        const interval = setInterval(() => {
            dispatch(getChamagotchiDetailsById(chamagotchiId))
            console.log("refreshing")
        }, 1000 * 30);
        return(() => {
            clearInterval(interval);
            dispatch(resetChamagotchi());
        })
    }, []);


    function copyTextToClipboard(text) {
        if (!navigator.clipboard) {
          fallbackCopyTextToClipboard(text);
          return;
        }
        navigator.clipboard.writeText(text).then(function() {
            toast.success( "Copied" + text , {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            },);
        }, function(err) {
          console.error('Async: Could not copy text: ', err);
        });
      }
    
    function fallbackCopyTextToClipboard(text) {
        var textArea = document.createElement("textarea");
        textArea.value = text;
        
        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";
      
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
      
        try {
          var successful = document.execCommand('copy');
          var msg = successful ? 'successful' : 'unsuccessful';
          console.log('Fallback: Copying text command was ' + msg);
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
        }
      
        document.body.removeChild(textArea);
      }


    return (
        <Fragment>
            <div className='app_container bg_white position-relative chamagotchi_details p-2'>
                { loading && <div className="p-5"><FadeLoader loading={loading} color='#01BFD9'/></div>}
                {(ChamagotchiDetailsById && !loading) ?
                    <Fragment>
                        <div className="row p-2">
                            <div className="col-12 col-md-4">
                                <img 
                                    src={ChamagotchiDetailsById.imagePath ? ChamagotchiDetailsById.imagePath : 'img/blank-chamagotchi.jpg' } 
                                    alt="" 
                                    className="chamagotchi_details_img w-100 p-2" 
                                />
                            </div>
                            <div className="col-12 col-md-8 text-left mt-2 mt-md-5">
                                <div className="row mb-2">
                                    <span className="col-3">Chamagotchi Id :</span>
                                    <span className="col-9 text-break">
                                        <b>{(ChamagotchiDetailsById.chamagotchiId != null) ? ChamagotchiDetailsById.chamagotchiId : 'NA'}</b>
                                    </span>
                                </div>
                                <div className="row mb-2">
                                    <span className="col-3">Chamagotchi Token Id :</span>
                                    <span className="col-9 text-break">
                                        <b>{(ChamagotchiDetailsById.chamagotchiTokenId != null) ? ChamagotchiDetailsById.chamagotchiTokenId : 'NA'}</b>
                                    </span>
                                </div>
                                {/*<div className="row mb-2">
                                    <span className="col-3">Token Id :</span>
                                    <span className="col-9 text-break">
                                        <b>{(ChamagotchiDetailsById.tokenId != null) ? ChamagotchiDetailsById.tokenId : 'NA'}</b>
                                    </span>
                                </div>*/}
                                <div className="row mb-2">
                                    <span className="col-3">Account Code :</span>
                                    <span className="col-9 text-break">
                                        <b className='mr-1'>{(ChamagotchiDetailsById.accountCode != null) ? ChamagotchiDetailsById.accountCode : 'NA'}</b>
                                        {(ChamagotchiDetailsById.accountCode != null) ? <FaRegCopy color='#01BFD9' size={16} onClick={() => {copyTextToClipboard(ChamagotchiDetailsById.accountCode )}}/>:null}
                                    </span>
                                </div>
                                <div className="row mb-2">
                                    <span className="col-3">Mint Txn Id :</span>
                                    <span className="col-9 text-break">
                                        <b className='mr-1'>{(ChamagotchiDetailsById.mintTxnId != null) ? ChamagotchiDetailsById.mintTxnId : 'NA'}</b>
                                        {(ChamagotchiDetailsById.mintTxnId != null) ? <FaRegCopy color='#01BFD9' size={16} onClick={() => {copyTextToClipboard(ChamagotchiDetailsById.mintTxnId )}}/>:null}
                                    </span>
                                </div>
                                <div className="row mb-2">
                                    <span className="col-3">Airdrop Txn Id :</span>
                                    <span className="col-9 text-break">
                                        <b className='mr-1'>{(ChamagotchiDetailsById.airdropTxnId != null) ? ChamagotchiDetailsById.airdropTxnId : 'NA'}</b>
                                        {(ChamagotchiDetailsById.airdropTxnId != null) ? <FaRegCopy color='#01BFD9' size={16} onClick={() => {copyTextToClipboard(ChamagotchiDetailsById.airdropTxnId )}}/>:null}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <ChamagotchiDetailsTable feedArray={ChamagotchiDetailsById.feeds} loading={loading}/>
                    </Fragment>
                    :
                    null
                }
                <button className="btn btn-primary btn-sm" onClick={() => history.goBack()}> 
                    <FaChevronLeft size={20} color='#fff'/>
                    Back
                </button>
            </div>
            <Footer />
        </Fragment>
    )
}

export default ChamagotchiDetails
