import React, { useEffect, useState } from 'react';
import { BiMedal } from "react-icons/bi";
import { BsEmojiDizzy, BsEmojiLaughing } from "react-icons/bs";
import { toast } from 'react-toastify';
import { getRemainingTimeUntilMsTimestamp } from '../ChamagotchiNftCardWrapper/utils/CountdownTimerUtils';
import './LeaderboardComponent.css';

function LeaderboardComponent({ nftImg, userName, rank, tokanId, accountCode, deadTime, nextFeedEndTime }) {

    var defaultRemainingTime = {
        seconds: '00',
        minutes: '00',
        hours: '00',
        days: '00',
    };
    const [remainingTime, setRemainingTime] = useState(defaultRemainingTime);
    var countdownTimestampMs = nextFeedEndTime ? new Date(nextFeedEndTime).getTime() : new Date().getTime();

    useEffect(() => {
        const intervalId = setInterval(() => {
            updateRemainingTime(countdownTimestampMs);
        }, 1000);
        return () => clearInterval(intervalId);
    },[countdownTimestampMs]);

    function updateRemainingTime(countdown) {
        setRemainingTime(getRemainingTimeUntilMsTimestamp(countdown));
    }

    function copyTextToClipboard(text) {
        if (!navigator.clipboard) {
          fallbackCopyTextToClipboard(text);
          return;
        }
        navigator.clipboard.writeText(text).then(function() {
            toast.success( "Copied" + text , {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            },);
        }, function(err) {
          console.error('Async: Could not copy text: ', err);
        });
      }
    
    function fallbackCopyTextToClipboard(text) {
        var textArea = document.createElement("textarea");
        textArea.value = text;
        
        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";
      
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
      
        try {
          var successful = document.execCommand('copy');
          var msg = successful ? 'successful' : 'unsuccessful';
          console.log('Fallback: Copying text command was ' + msg);
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
        }
      
        document.body.removeChild(textArea);
      }

    return (
        <div className='leaderboard_component d-flex justify-content-between align-items-center m-2 px-2'>
            <div className="leaderboard_nftImg mr-2">
                <img src={nftImg ? nftImg : 'img/blank-chamagotchi.jpg'} alt="" className="" /> 
            </div>
            <div className="leaderboard_name">{tokanId ? tokanId : 'NA'}</div>
            <div className="leaderboard_account_Code cursor-pointer" data-toggle="tooltip" data-placement="top" title="Copy" onClick={() => copyTextToClipboard(accountCode ? accountCode : null)}>{accountCode ? accountCode : null}</div>
            <div className="d-flex justify-content-between align-items-center">
                <div className=" mr-2">
                    <div className="clock flex-grow w-100 px-0 px-sm-1 ">
                        <span className='time'>
                            {remainingTime.hours}:
                            {remainingTime.minutes}:
                            {remainingTime.seconds}
                        </span>
                    </div>
                </div>
                <div className="leaderboard_rank mr-2">{rank ? rank : null}</div>
                <div className="leaderboard_medal">
                    {(deadTime) ?
                        <div className="d-flex flex-column align-items-center text-danger">
                            <BsEmojiDizzy size={24} color='#e8000d'/>
                            dead
                        </div>
                        :
                        <div className="d-flex flex-column align-items-center text-success">
                            <BsEmojiLaughing size={24} color='#32cd32'/>
                            Alive
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default LeaderboardComponent
