import React from 'react';
import { useHistory } from 'react-router-dom';
import Pet1 from '../../assets/Img/Layer 120.png';
import Pet2 from '../../assets/Img/Layer 116.png';
import Pet3 from '../../assets/Img/Layer 121.png';
import Pet4 from '../../assets/Img/Layer 119.png';
import { GiClick } from "react-icons/gi";
import './PickPetAlt.css';

function PickPetAlt({setActivePage}) {

    const history = useHistory();

    return (
        <div className="app_container bg_white pb-5">
            <div className="pick_your_pet_container2 d-flex flex-column">
                <div className="d-flex flex-wrap">
                    <div className="pet_card_alt mb-4">
                        <img src={Pet1} alt="" className="" />
                    </div>
                    <div className="pet_card_alt mb-4">
                        <img src={Pet2} alt="" className="" />
                    </div>
                    <div className="pet_card_alt mb-4">
                        <img src={Pet3} alt="" className="" />
                    </div>
                    <div className="pet_card_alt mb-4">
                        <img src={Pet4} alt="" className="" />
                    </div>
                </div>
            </div>
            {/* create Chamagotchi mainpage btn */}
            {/* <span className="btn btn-outline-primary d-flex justify-content-between px-4 w-25 mx-auto" onClick={() => {history.push('/design-your-pet');setActivePage('design-your-pet')}}>
                Create Chamagotchi Now
                <GiClick className='ml-5 btn__info'color='#7031BD' size={24}/>
            </span> */}
        </div>
    )
}

export default PickPetAlt
