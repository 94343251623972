import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import OpenseaIconDark from '../../assets/Img/opensea-icon-white.png';
import Logo from '../../assets/Img/logo.png';
import { FaClipboardCheck, FaDiscord, FaPuzzlePiece, FaRegListAlt, FaSignOutAlt, FaUser, FaUserEdit } from 'react-icons/fa';
import { BiMedal } from "react-icons/bi";
import { FcFaq, FcPuzzle } from "react-icons/fc";
import { IoColorPaletteSharp, IoMenu } from 'react-icons/io5';
import SideNav from './SideNav/SideNav';
import { userLogout } from '../../redux/actions/users';
import './Navbar.css';

function Navbar({ isSignedUp, setAccountNo, setUserId, activePage, setActivePage }) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [profilePic] = useState('img/profile-pic.png');

    const history = useHistory();
    const dispatch = useDispatch();

    const node = useRef();
    const onClickAway = (e) => {
        if(node.current.contains(e.target)){
          return ;
        }else {
            setIsDropdownOpen(false);
        }
    } 
  
    useEffect(() => {
      document.addEventListener("mousedown", onClickAway);
      return () => {
        document.removeEventListener("mousedown", onClickAway);
      };
    }, []);
    
    const status = useSelector(state => state.users.loginStatus);
    const user = useSelector(state => state.users.user);

    // useEffect(() => {
    //     if(status !== 200) {
    //         history.push('/signup');
    //     }
    // }, [status]);

    function openInNewTab(url) {
        const win = window.open(url, '_blank');
        if (win != null) {
          win.focus();
        }
      }

    return (
        <div className="navbar position-sticky top-0">
            <div className="w-100 d-flex justify-content-between align-items-center position-relative">
                <div className="nav_logo" onClick={() => {history.push('/');setActivePage('')}}>
                        <h2 className="OxChams_Nav">0xCHAMS</h2>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                    <div className="d-flex justify-content-center align-items-center">
                        {/*<div
                            className={(activePage === 'chamagotchi') ? "active_nav nav_link cursor-pointer mx-3 d-none d-lg-flex" : "cursor-pointer mx-3 d-none d-lg-flex nav_link"} 
                            onClick={() => {
                                history.push('/chamagotchi');
                                setActivePage('chamagotchi');
                            }}
                        >
                            <b>Chamagotchi</b>
                        </div>
                        <div 
                            className={(activePage === 'leaderboard') ? "active_nav nav_link cursor-pointer mx-3 d-none d-md-flex" : "cursor-pointer mx-3 d-none d-md-flex nav_link"} 
                            onClick={() => {
                                history.push('/leaderboard');
                                setActivePage('leaderboard');
                            }}
                        >
                            <b>Leaderboard</b>
                        </div>*/}
                        {/* create Chamagotchi navbar btn */}
                        {/* <div 
                            className={(activePage === 'design-your-pet') ? "active_nav nav_link cursor-pointer mx-3 d-none d-md-flex" : "cursor-pointer mx-3 d-none d-md-flex nav_link"}  
                            onClick={() => {
                                history.push('/design-your-pet');
                                setActivePage('design-your-pet');
                            }}
                        >
                            <b>Design</b>
                        </div> */}
                       {/* <div
                            className={(activePage === 'faq') ? "active_nav nav_link cursor-pointer mx-3 d-none d-md-flex mr-5" : "cursor-pointer mx-3 d-none d-md-flex mr-5 nav_link"}  
                            onClick={() => {
                                history.push('/faq');
                                setActivePage('faq');
                            }}
                        >
                            <b>FAQ</b>
                        </div>*/}
                        <a href="https://t.co/7yjP8YT5Eb?amp=1" title="Discord" target="_blank" className="nav_icons  d-none d-md-flex">
                            <FaDiscord size={40} color={"#fff"}/>
                        </a>
                        <a href="https://opensea.io/collection/0xchams" target="_blank" className="nav_icons d-none d-md-flex mr-5">
                            <img src={OpenseaIconDark} alt="" />
                        </a>
                    </div>
                    {/*{(isSignedUp) ?
                        <div ref={node} className="profile_icon position-relative">
                            {(user.profileImage) ?
                                <img src={user.profileImage} alt="" className="" onClick={() => setIsDropdownOpen(!isDropdownOpen)}/>
                                :
                                <img src={profilePic} alt="" className="" onClick={() => setIsDropdownOpen(!isDropdownOpen)}/>
                            }
                            <div className={`${(isDropdownOpen) ? "profile_icon_dropdown bg-light mt-2 rounded-3 d-flex flex-column align-items-start justify-content-center position-absolute" : "d-none"}`}>
                                <div className="profile_icon_dropdown_icon  m-1" onClick={() => {history.push('/dashboard');setIsDropdownOpen(false);setActivePage('dashboard')}}>
                                    <img src='img/dashboard-icon.png' alt="dashboard-icon.png" className='dropdown_svg_icon'/>
                                     <FaClipboardCheck size={20}/>
                                    <span>Dashboard</span>
                                </div>
                                 <div className="profile_icon_dropdown_icon d-flex d-md-none m-1" onClick={() => {history.push('design-your-pet/');setIsDropdownOpen(false);setActivePage('design-your-pet')}}>
                                    <img src='img/create-icon.png' alt="create-icon.png" className='dropdown_svg_icon'/>
                                    <span>Design</span>
                                </div>
                                <div className="profile_icon_dropdown_icon d-flex d-lg-none m-1" onClick={() => {history.push('/chamagotchi');setIsDropdownOpen(false);setActivePage('chamagotchi')}}>
                                    <img src='img/nft-icon.png' alt="nft-icon.png" className='dropdown_svg_icon'/>
                                     <BiMedal size={20}/>
                                    <span>Chamagotchi</span>
                                </div>
                                <div className="profile_icon_dropdown_icon d-flex d-md-none m-1" onClick={() => {history.push('/leaderboard');setIsDropdownOpen(false);setActivePage('leaderboard')}}>
                                    <img src='img/leaderboard-icon.png' alt="leaderboard-icon.png" className='dropdown_svg_icon'/>
                                     <FaRegListAlt size={20} color='purple'/>
                                    <span>Leaderboard</span>
                                </div>
                                <div className="profile_icon_dropdown_icon  m-1" onClick={() => {history.push('/0xChams');setIsDropdownOpen(false);setActivePage('OxChams')}}>
                                    <img src='img/OxChams-icon.svg' alt="OxChams-icon.svg" className='dropdown_svg_icon'/>
                                     <FaClipboardCheck size={20}/>
                                    <span>0xChams</span>
                                </div>
                                <hr className="dropdown_hr m-1" />
                                <div className="profile_icon_dropdown_icon d-flex m-1" onClick={() => {history.push('/profile');setIsDropdownOpen(false);setActivePage('profile')}}>
                                    <img src='img/chameleon-icon.png' alt="chameleon-icon.png" className='dropdown_svg_icon'/>
                                     <FaUser size={20}/>
                                    <span>Profile</span>
                                </div>
                                <div className="profile_icon_dropdown_icon d-flex m-1" onClick={() => {history.push('/edit-profile');setIsDropdownOpen(false);setActivePage('edit-profile')}}>
                                    <img src='img/edit-profile-icon.png' alt="edit-profile-icon.png" className='dropdown_svg_icon'/>
                                     <FaUserEdit size={20}/>
                                    <span>Edit Profile</span>
                                </div>
                                <div 
                                    className="profile_icon_dropdown_icon d-flex m-1" 
                                    onClick={() => {
                                        setAccountNo(null);
                                        setUserId(null);
                                        setIsDropdownOpen(false);
                                        setActivePage('')
                                        dispatch(userLogout())
                                    }}
                                >
                                    <img src='img/signout-icon.png' alt="signout-icon.png" className='dropdown_svg_icon'/>
                                     <FaSignOutAlt size={20}/>
                                    <span>Sign Out</span>
                                </div>
                                <hr className="dropdown_hr m-1 d-flex d-md-none" />
                                <div className="profile_icon_dropdown_icon d-flex d-md-none m-1" onClick={() => {openInNewTab('https://t.co/7yjP8YT5Eb?amp=1');setIsDropdownOpen(false);setActivePage('')}}>
                                    <img src='img/discord-icon.svg' alt="discord-icon.svg" className='dropdown_svg_icon'/>
                                    <span>Discord</span>
                                </div>
                                <div className="profile_icon_dropdown_icon d-flex d-md-none m-1" onClick={() => {openInNewTab('https://opensea.io/collection/chameleon-collective');setIsDropdownOpen(false);setActivePage('')}}>
                                    <img src='img/opensea-icon.svg' alt="opensea-icon.svg" className='dropdown_svg_icon'/>
                                    <span>Opensea</span>
                                </div>
                                <div className="profile_icon_dropdown_icon d-flex d-md-none m-1" onClick={() => {history.push('/faq');setIsDropdownOpen(false);setActivePage('faq')}}>
                                    <FcFaq size={20}/>
                                    <span>FAQ</span>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="hamburger_menu" onClick={() => setIsSidebarOpen(true)}>
                            <IoMenu size={50} color={"#000"}/>
                        </div>
                    }*/}
                    <div className={`${isSidebarOpen ? "side_nav position-fixed" : "d-none"}`}>
                        <SideNav setIsSidebarOpen={setIsSidebarOpen}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar
