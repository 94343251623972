import React from 'react';
import RaffleIcon from "../../assets/Img/raffle-icon.png";
import MoneyIcon from "../../assets/Img/money-icon.png";
import './ChamagotchiInfo.css';

function ChamagotchiInfo() {
    return (
            
        <div className="d-flex flex-column">
            <span className="coloredTitle pink_bg mr-auto">THE RULES</span>
            <div className="text_content_2">
                <p style={{textAlign: "left"}}><b>Login in each day to feed your pet before the timer runs out!</b> Every time you feed it the timer resets but the time to next feeding goes down by 20 min, so be careful not to miss a feeding because then your pet dies and your timer sets to 0!</p>
            </div>
            <span className="coloredTitle green_bg mr-auto">THE REWARDS</span>
            <div className="text_content_3">
                <p style={{textAlign: "left"}} className="mb-2"><b><i>60% of the entire prize pool from minting costs will be put into prizing distribution!</i></b> Explained:</p>
                <div className="d-flex">
                    <img src={MoneyIcon} alt="" className="money_icon" />
                    <div className=" mb-1">
                        <p style={{textAlign: "left"}} >Every 1000 sold, 5 winners will split 50% of the prize pool.</p>
                        <p style={{textAlign: "left"}}> Every 2000 sold, 10 winners will split 50% of the prize pool.</p> 
                        <p style={{textAlign: "left"}}><b>Summary: Every 1000 sold, it adds 5 winners to the prize pool.</b> For example, if 5,000 sell that means the top 25 winners split a ~140 ETH pot, that means you could win like $20,000 each! Can't say we never did nothin' for ya ;)</p>
                    </div>
                </div>
                <div className="d-flex">
                    <img src={RaffleIcon} alt="" className="money_icon2" />
                    <div className=" mt-3">
                        <p style={{textAlign: "left"}}>10% of the prize pool will be split to random holders, as well as raffles for Chamagotchi</p>
                    </div>
                </div>
                <div className="">
                    <p style={{textAlign: "left", margin: "16px 0 0 0"}}><b>The remaining 40%:</b> <br /></p>
                    <p style={{textAlign: "left", margin: "0 0 0 40px "}}>Charity - 10% </p>
                    <p style={{textAlign: "left", margin: "0 0 0 40px "}}>Game Dev/Web Dev/Marketing costs - 30%</p>
                </div>
            </div>
		</div>
        
    )
}

export default ChamagotchiInfo
