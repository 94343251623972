import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTokenByUser } from '../../redux/actions/users';
import NftCard from '../NftCard/NftCard';
import NftPopup from '../NftPopups/NftPopup';
import { GridLoader } from 'react-spinners';
import './NftCardWrapper.css';

function NftCardWrapper({ 
    userId,
    setPopupNftImgUrl,
    setPopupNftTokanNo,
    setShowNftPopup, }) {
    const [isShowMore, setIsShowMore] = useState(false);

    const dispatch = useDispatch();
    const user = useSelector(state => state.users.user);
    const chameleonNft = useSelector(state => state.users.chameleonNft);
    const loading = useSelector(state => state.users.loading);
    const error = useSelector(state => state.users.error);

    useEffect(() => {
        dispatch(getTokenByUser(user.userId))
    }, [user, user.userId])

    return (
        <div className='nft_card_wrapper mx-auto m-md-4 mb-4'>
            <div className="nft_card_wrapper_heading d-flex justify-content-between">
                <h1 className="nftCard_heading text-uppercase p-4 m-0 pointer-cursor">chameleon</h1>
                <span className="down_icon m-4 down_icon_primary" onClick={() => setIsShowMore(!isShowMore)}>
                    {(!isShowMore) ?
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-40 w-40" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-40 w-40" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd" />
                        </svg>
                    }
                </span>
            </div>
            <div className="d-flex flex-wrap p-2">
                {
                    loading &&
                    <div className="d-flex justify-content-center align-item-end w-100 p-5">
                        <GridLoader loading={loading} size={30} color='#01BFD9'/>
                    </div>
                }
                {(chameleonNft.length && !loading) ?
                    chameleonNft?.map(( nftImg, index ) => {
                        return(
                            <Fragment>
                                <NftCard 
                                    key={index} 
                                    nftImgUrl={nftImg.image} 
                                    tokanNo={nftImg.token} 
                                    index={index}
                                    isShowMore={isShowMore}
                                    setShowPopup={setShowNftPopup}
                                    setPopupNftImgUrl={setPopupNftImgUrl}
                                    setPopupNftTokanNo={setPopupNftTokanNo}
                                />
                            </Fragment>
                        )
                    })
                    : (!loading) ?
                    <div className="d-flex justify-content-center align-items-end w-100 p-4">
                        <img src="img/no-data.png" alt="" className="" />
                        <h4 className="text-danger my-2">You don't have any NFT associated With this account !!!</h4>
                    </div>
                    :
                    null
                }
                {(!isShowMore && chameleonNft.length && !loading) ?
                    <div className="nft-show-more-card d-flex d-md-none justify-content-center align-item-center">
                        <button className="btn btn-outline-info" onClick={() => setIsShowMore(true)}>
                            {"More >>"}
                        </button>
                    </div>
                    :( chameleonNft.length && !loading) ?
                        <div className="nft-show-more-card d-flex d-md-none justify-content-center align-item-center">
                            <button className="btn btn-outline-info" onClick={() => setIsShowMore(false)}>
                                {"collapse <<"}
                            </button>
                        </div>
                    :
                    null
                }
            </div>
        </div>
    )
}

export default NftCardWrapper
