import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { findByTraitType } from '../../../redux/actions/chamagotchi';
import { GridLoader } from 'react-spinners';
import '../DesignYourPetAssets.css';

function HronTail({ selectedHornTail, setSelectedHornTail, setSelectedHornTailId }) {
    const [traitType, setTraitType] = useState("Horn / Tail")
    const [selectedImgUrl, setSelectedImgUrl] = useState("");

    const dispatch = useDispatch();
    const traits = useSelector(state => state.chamagotchi.traits);
    const loading = useSelector(state => state.chamagotchi.loading);
    const error = useSelector(state => state.chamagotchi.error);

    useEffect(() => {
        dispatch(findByTraitType(traitType));
    }, []);

    return (
        <div className="thumbnails_wrapper d-flex flex-wrap p-2 p-sm-4">
            {(loading) ?
            <div className="d-flex justify-content-center align-items-end w-100 p-5">
                <GridLoader loading={loading} size={30} color='#e486a1'/>
            </div>
                :
                !loading && traits.length && traits?.map((thumb, index) => { 
                    return(
                        thumb.image && thumb.thumbnail &&
                        <img 
                            key={index}
                            src={`img/pet-design-assets/HORN-TAIL/thumb/${thumb.thumbnail}`} 
                            alt="img-url" 
                            className={`thumbnanil ${(`img/pet-design-assets/HORN-TAIL/${thumb.image}` === selectedHornTail) ?  "Selected_thumbnail" : ""}`}
                            onClick={() => {
                                setSelectedHornTail(`img/pet-design-assets/HORN-TAIL/${thumb.image}`)
                                setSelectedHornTailId(thumb.traitTypeId);
                            }} 
                        />
                    )
                })
            }
        </div>
    )
}

export default HronTail
