import React from 'react';
import PolygonIcon from '../../assets/Img/polygon-icon.png';
import MintIcon from '../../assets/Img/mint-icon.png';
import TimeIcon from '../../assets/Img/time-icon1.png';
import EthIcon from '../../assets/Img/eth-icon.png';
import MoneyIcon from '../../assets/Img/money-icon1.png';
import RaffleIcon from '../../assets/Img/raffle-icon1.png';

import './IconsHeader.css';

function IconsHeader() {
    return (
        <div className="app_container">
            <div className="icons_header d-flex justify-content-between align-items-center flex-nowrap">
                <div className="d-flex flex-nowrap flex-1">
                    <img src={PolygonIcon} alt="" className="mx-2" />
                    POLYGON
                </div>
                <div className="d-flex flex-nowrap flex-1">
                    <img src={MintIcon} alt="" className="mx-2" />
                    NO MINT LIMIT
                </div>
                <div className="d-flex flex-nowrap flex-1">
                    <img src={TimeIcon} alt="" className="mx-2" />
                    72 HRS
                </div>
                <div className="d-flex flex-nowrap flex-1">
                    <img src={EthIcon} alt="" className="mx-2" />
                    .025-.05 ETH
                </div>
                <div className="d-flex flex-nowrap flex-1">
                    <img src={MoneyIcon} alt="" className="heder_money_icon mx-2" />
                    50% SPLIT POT
                </div>
                <div className="d-flex flex-nowrap flex-1">
                    <img src={RaffleIcon} alt="" className="mx-2" />
                    10% RAFFLED
                </div>
            </div>
        </div>
    )
}

export default IconsHeader
