import React, { Fragment, useEffect, useState } from 'react';
import AppLayoutPrimary from '../../components/AppLayoutPrimary/AppLayoutPrimary';
import ChamagotchiNftCardWrapper from '../../components/ChamagotchiNftCardWrapper/ChamagotchiNftCardWrapper';
import NftCardWrapper from '../../components/NftCardWrapper/NftCardWrapper';
import './Dashboard.css';
import { useDispatch, useSelector } from 'react-redux';
import NftPopup from '../../components/NftPopups/NftPopup';
import { resetChamagotchi } from '../../redux/actions/chamagotchi';

function Dashboard({ accountNo, setAccountNo, userId, setUserId, setActivePage, setChamagotchiIdForEdit, setAccountAdd, setAirdropTxnId }) {
    const [profilePic] = useState('img/profile-pic.png');
    const [BgImgUrl] = useState('img/bg-1.jpg');
    const [showNftPopup, setShowNftPopup] = useState(false);
    const [popupNftImgUrl, setPopupNftImgUrl] = useState('');
    const [popupNftTokanNo, setPopupNftTokanNo] = useState('');
    const [nextFeedEndTime, setNextFeedEndTime] = useState('');
    const [isLive, setIsLive] = useState('');
    const [deadTime, setDeadTime] = useState('');
    const [errorMsg, setErrorMsg] = useState('');


    const dispatch = useDispatch();
    const user = useSelector(state => state.users.user);
    const loading = useSelector(state => state.users.loading);
    const error = useSelector(state => state.users.error);
    
    useEffect(() => {
        setActivePage('dashboard');
        return () => {
            setActivePage('');
            dispatch(resetChamagotchi());
        }
    }, []);

    return (
        <Fragment>
            <NftPopup
                show={showNftPopup}
                onClose={(state) => {
                    setShowNftPopup(state);
                }}
                nftImgUrl={popupNftImgUrl} 
                tokanNo={popupNftTokanNo}
                nextFeedEndTime={nextFeedEndTime}
                userId={userId}
                setShowNftPopup={setShowNftPopup}
                isLive={isLive}
                setIsLive={setIsLive}
                deadTime={deadTime}
                setDeadTime={setDeadTime}
                setAccountNo={setAccountNo}
                setUserId={setUserId}
                setActivePage={setActivePage}
            />
            <AppLayoutPrimary 
                setUserId={setUserId}
                setAccountNo={setAccountNo}
                BgImgUrl={BgImgUrl}
                content={
                    <Fragment>
                        {/* {loading && <h4 className="text-info m-1 ">loading...</h4>}          */}
                        {(user) ?
                            <div className='Profile d-flex flex-column justify-content-center position-relative text-secondary w-100'>
                                <div className="profile_img position-absolute">
                                    {(user.profileImage) ? <img src={user.profileImage} alt="profile-img" /> : <img src={profilePic} alt="default-profile-img" />}
                                </div>
                                <h3 className="userName">{user.userName}</h3>
                                {(user.discordName) ? <h4 className="sub_text my-1">{user.discordName}</h4> : null}
                                <h3 className="email my-1">{user.email}</h3>
                                <NftCardWrapper 
                                    userId={userId}
                                    setPopupNftImgUrl={setPopupNftImgUrl}
                                    setPopupNftTokanNo={setPopupNftTokanNo}
                                    setShowNftPopup={setShowNftPopup}
                                />
                                <span className="m3"></span>
                                <ChamagotchiNftCardWrapper 
                                    userId={userId} 
                                    setActivePage={setActivePage} 
                                    setChamagotchiIdForEdit={setChamagotchiIdForEdit}
                                    setAccountAdd={setAccountAdd}
                                    setPopupNftImgUrl={setPopupNftImgUrl}
                                    setPopupNftTokanNo={setPopupNftTokanNo}
                                    setNextFeedEndTime={setNextFeedEndTime}
                                    setIsLive={setIsLive}
                                    setDeadTime={setDeadTime}
                                    setErrorMsg={setErrorMsg}
                                    errorMsg={errorMsg}
                                    setShowNftPopup={setShowNftPopup}
                                    setAirdropTxnId={setAirdropTxnId}
                                />
                            </div>
                            :
                            null
                        }
                    </Fragment>
                }
            />
        </Fragment>
    )
}

export default Dashboard
