import React, { Fragment, useEffect } from 'react';
import Footer from '../../components/Footer/Footer';
import OxChamsImg from '../../assets/Img/0xChams.png';
import OxChamsImgThree from '../../assets/Img/wgmi.png';
import FounderZach from '../../assets/Img/zach.png';
import FounderZachSign from '../../assets/Img/zachsig.png';
import './OxChams.css';
import OxChamsMintingSec from '../../components/OxChamsMintingSec/OxChamsMintingSec';

function OxChams({ setActivePage }) {
       
    useEffect(() => {
        setActivePage('faq');
        return () => {
            setActivePage('');
        }
    }, []);

  return (
        <Fragment>
            <div className='app_container bg_white OxChams'>
                <header className='OxChams_header w-100 d-flex flex-column justify-content-center align-items-center'>
                    <h2 className="">0xCHAMS</h2>
                    <h3>FREE FOR CHAMELEON COLLECTIVE HOLDERS</h3>
                </header>
                <div className="OxChams_hero px-2 px-sm-5">
                    <div className="OxChams_top_img position-relative px-2 px-sm-5">
                        <img src={OxChamsImg} alt="" className="w-100" />
                    </div>
                    <div className="OxChams_text text-left pt-0 pt-md-5 pr-md-5 pr-md-5 pl-md-0 px-sm-5">
                        <p className="my-3 pr-md-5 pr-md-5 pl-md-0 px-2 px-sm-5">Continuing to see the NFT space grow and evolve in its own decentralized fashion, made us decide to take a deeper look into our own sold out 10k collection, and say..."hey, can we do even better, with our collection?"</p>
                        <p className="my-3 pr-md-5 pr-md-5 pl-md-0 px-2 px-sm-5"><b>Chams, as you know, are all about fun and laid-back vibes, so also in pure satire fashion 0xChams has emerged...perfect timing, right!?</b></p>
                        <p className="my-3 pr-md-5 pr-md-5 pl-md-0 px-2 px-sm-5">As the original creator of Chameleon Collective, and as an artist, I've had my work stolen over the years—someone would place a filter over it, change the color, or do a simple crop and then use it however they'd like. This sucks! Especially when you've spent countless hours making each piece, not to mention, all the years of training and honing your craft.</p>
                        <p className="my-3 pr-md-5 pr-md-5 pl-md-0 px-2 px-sm-5">What releasing 0xCham collection has now allowed, is for us to hand-pick and curate to perfection only using our favorite traits from the main collection, resulting in the coolest possible combo, making every holder excited by what they were collecting!</p>
                    </div>
                    <p className="col-12 OxChams_text text-left px-sm-0 mt-2 px-2 pr-sm-5 mb-0">Community first is what we're about, so providing more value to our Cham Fam was a no-brainer: highest quality of traits above all for everyone, and provide our original holders with free mints in the process. As both owners of BAYC & 0x — we're extending a mint discount to both - cause we're all fam right? ;)</p>
                    <div className="col-9 col-sm-6 col-md-4 ml-auto d-flex">
                        <img src={FounderZach} alt="" className="founder_zach_img" />
                        <img src={FounderZachSign} alt="" className="founder_zach_img_sign" />
                    </div>
                </div>
                {/* <OxChamsMintingSec /> */}
                <div className="col-12 d-flex justify-content-end w-100 OxChams_img_three mt-4">
                    <img src={OxChamsImgThree} alt="" className="position-relative" />
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default OxChams;