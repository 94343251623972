import React from 'react';
import BannerImg from '../../assets/Img/banner-img.png';
import ChamagotchiLogo from '../../assets/Img/chamagotchi-logo.png';
import FeedButton from '../../assets/Img/feed-button.png';
import './ChamagotchiHeader.css';

function ChamagotchiHeader() {
    return (
        <div className="app_container">
            <div className="d-flex position-relative ">
                <img className="w-100 h-100" src={BannerImg} alt="" />
                <div className="position-absolute w-50 p-5 d-flex flex-column">
                    <span className="feed_banner_title text-center m-0" >THE</span>
                    <img className="w-100" src={ChamagotchiLogo} alt="" />
                    <img className="w-50 mx-auto feed-btn" src={FeedButton} alt="" data-toggle="modal" data-target="#myModal" />
                </div>
            </div>        
        </div>
    )
}

export default ChamagotchiHeader
