import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AppLayoutPrimary from '../../components/AppLayoutPrimary/AppLayoutPrimary';
import Twitter from '../../assets/Img/ftr_twitter.png';
import Instagram from '../../assets/Img/ftr_insta.png';
import { useDispatch, useSelector } from 'react-redux';
import { FadeLoader, PulseLoader }  from 'react-spinners';
import './Profile.css';

function Profile({ accountNo, setAccountNo, setUserId, setActivePage }) {
    const [profilePic] = useState('img/profile-pic.png');
    const [BgImgUrl] = useState('img/bg-1.jpg');
    const  history = useHistory();

    const dispatch = useDispatch();
    const user = useSelector(state => state.users.user);
    const loading = useSelector(state => state.users.loading);
    const error = useSelector(state => state.users.error);

    // useEffect(() => {
    //     dispatch(getUserDetailByAddress(accountNo));
    // }, []);
    
    useEffect(() => {
        setActivePage('profile');
        return () => {
            setActivePage('');
        }
    }, []);
    
    return (
        <AppLayoutPrimary 
            setUserId={setUserId}
            setAccountNo={setAccountNo}
            BgImgUrl={BgImgUrl}
            content={
                <Fragment>
                    {loading && <div className="p-5"><FadeLoader loading={loading} color='#01BFD9'/></div>}         
                    {(user && !loading) ?
                        <div className='Profile d-flex flex-column justify-content-center position-relative text-secondary'>
                            <div className="profile_img position-absolute">
                                {(loading && <div className="loaderDiv position-absolute"><PulseLoader loading={loading} color="#7B0083"/></div>)}
                                {(user.profileImage && !loading) ? <img src={user.profileImage} alt="profile-img" /> : <img src={profilePic} alt="profile-img" />}
                            </div>
                            <h3 className="userName">{user.userName}</h3>
                            <h4 className="sub_text">{user.discordName}</h4>
                            <div className="d-flex mini_social_icon justify-content-between">
                                <a href='https://twitter.com/home?lang=en' target='_blank'><img src={Twitter} alt="twitter" className="twitter"/></a>
                                <a href='https://www.instagram.com/' target='_blank'><img src={Instagram} alt="instagram" className="instagram" /></a>
                            </div>
                            <h3 className="email">{user.email}</h3>
                            <div className="ets_addresses d-flex flex-column justify-content-center">
                            {(user.accounts && user.accounts.length) ?
                                user.accounts.map((account, index) => {
                                    return (
                                        <span key={index} className="eth_add">{account.accountCode}</span>
                                    )
                                })
                                :
                                <span className="eth_add">{accountNo}</span>
                            }
                            </div>
                            <button className="edit_profile_btn mx-auto" onClick={() => history.push('./edit-profile')}>Edit Profile</button>
                        </div>
                        :
                        null
                    }
                </Fragment>
            }
            accountNo={accountNo}
        />
    )
}

export default Profile
