import React, { Fragment, useEffect,  useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { findChamagotchiByUser } from '../../redux/actions/chamagotchi';
import ChamagotchiNftCard from '../ChamagotchiNftCard/ChamagotchiNftCard';
import NftPopup from '../NftPopups/NftPopup';
import { GiClick } from "react-icons/gi";
import { FaPlus } from "react-icons/fa";
import { GridLoader } from 'react-spinners';
import './ChamagotchiNftCardWrapper.css';

function ChamagotchiNftCardWrapper({ 
    userId, 
    setActivePage, 
    setChamagotchiIdForEdit,
    setAccountAdd,
    setPopupNftImgUrl,
    setPopupNftTokanNo,
    setNextFeedEndTime,
    setIsLive,
    setDeadTime,
    setErrorMsg,
    setShowNftPopup,
    setAirdropTxnId, }) {
    const [isShowMore, setIsShowMore] = useState(false);
    // const [canEdit, setCanEdit] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch();

    const user = useSelector(state => state.users.user);
    const chamagotchis = useSelector(state => state.chamagotchi.chamagotchis);
    const loading = useSelector(state => state.chamagotchi.loading);
    const error = useSelector(state => state.chamagotchi.error);

    useEffect(() => {
        dispatch(findChamagotchiByUser(user.userId))
    }, [user, user.userId]);

    // console.log("chamagotchis :", chamagotchis)
    return (
        <div className='chama_nft_card_wrapper mx-auto m-md-4 mb-4'>
            <div className="chama_nft_card_wrapper_heading d-flex justify-content-between">
                <h1 
                    className="nftCard_heading text-uppercase p-4 m-0" 
                    onClick={() => {
                        // history.push('/design-your-pet');setActivePage('design-your-pet')
                    }}
                >
                    chamagotchi
                </h1>
                {/* create Chamagotchi dashboard btn */}
                {/* {(chamagotchis && chamagotchis.length && !loading) ?
                    <Fragment>
                        <button 
                            className="create-new btn d-none d-md-flex justify-content-center" 
                            onClick={() => {history.push('/design-your-pet');setActivePage('design-your-pet')}}
                        >
                            Create New Chamagotchi
                        </button>
                        <button 
                            className="create-new btn d-none d-sm-flex d-md-none justify-content-center" 
                            onClick={() => {history.push('/design-your-pet');setActivePage('design-your-pet')}}
                        >
                            Create 
                        </button>
                        <button 
                            className="create-new btn d-flex d-sm-none justify-content-center" 
                            onClick={() => {history.push('/design-your-pet');setActivePage('design-your-pet')}}
                        >
                            <FaPlus size={20} />
                        </button>
                    </Fragment>
                     : null
                } */}
                <span className="chama_down_icon m-4" onClick={() => setIsShowMore(!isShowMore)}>
                    {(!isShowMore) ?
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-40 w-40" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-40 w-40" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd" />
                        </svg>
                    }
                </span>
            </div>
            <div className="d-flex flex-wrap p-2">
                {
                    loading &&
                    <div className="d-flex justify-content-center align-items-end w-100 p-5">
                        <GridLoader loading={loading} size={30} color='#7031BD'/>
                    </div>
                }
                {(chamagotchis && chamagotchis.length && !loading) ?
                    chamagotchis?.map(( chamagotchi, index ) => {
                        return(
                            <Fragment>
                                <ChamagotchiNftCard 
                                    key={index} 
                                    nftImgUrl={chamagotchi.imagePath}
                                    tokanNo={chamagotchi.chamagotchiId} 
                                    chamagotchiTokenId={chamagotchi.chamagotchiTokenId}
                                    accountCode={chamagotchi.accountCode}
                                    index={index}
                                    isShowMore={isShowMore}
                                    setShowNftPopup={setShowNftPopup}
                                    setPopupNftImgUrl={setPopupNftImgUrl}
                                    setPopupNftTokanNo={setPopupNftTokanNo}
                                    setNextFeedEndTime={setNextFeedEndTime}
                                    nextFeedEndTime={chamagotchi.nextFeedEndTime}
                                    isLive={chamagotchi.isLive}
                                    setIsLive={setIsLive}
                                    deadTime={chamagotchi.deadTime}
                                    setDeadTime={setDeadTime}
                                    setErrorMsg={setErrorMsg}
                                    errorMsg={error}
                                    canEdit={(chamagotchi.imagePath === null && chamagotchi.chamagotchiId !== null)}
                                    // canEdit={false}
                                    setChamagotchiIdForEdit={setChamagotchiIdForEdit}
                                    setAccountAdd={setAccountAdd}
                                    setActivePage={setActivePage}
                                    airdropTxnId={chamagotchi.airdropTxnId}
                                    setAirdropTxnId={setAirdropTxnId}
                                />
                                
                            </Fragment>
                        )
                    })
                    :(!loading) ?
                    <div className="d-flex justify-content-center align-items-end w-100 p-4">
                        <img src="img/no-data.png" alt="" className="" />
                        <div className="d-flex flex-column justify-content-center m-2">
                            <h4 className="text-danger mb-4">Don't have any Chamagotchi???</h4>
                            {/*<span className="btn btn-outline-primary d-flex justify-content-between px-4" onClick={() => {history.push('/design-your-pet');setActivePage('design-your-pet')}}>
                                Create Your Own Chamagotchi Now
                                <GiClick className='ml-5 btn__info'color='#7031BD' size={24}/>
                            </span>*/}
                        </div>
                    </div>
                    :
                    null
                }
                 {(!isShowMore && chamagotchis && chamagotchis.length && !loading) ?
                    <div className="nft-show-more-card d-flex d-md-none justify-content-center align-item-center">
                        <button className="btn btn-outline-info" onClick={() => setIsShowMore(true)}>
                            {"More >>"}
                        </button>
                    </div>
                    :( chamagotchis && chamagotchis.length && !loading) ?
                        <div className="nft-show-more-card d-flex d-md-none justify-content-center align-item-center">
                            <button className="btn btn-outline-info" onClick={() => setIsShowMore(false)}>
                                {"collapse <<"}
                            </button>
                        </div>
                    :
                    null
                }
            </div>
        </div>
    )
}

export default ChamagotchiNftCardWrapper
