import { call, put, takeEvery } from 'redux-saga/effects';
import { 
    getTokenByUserApi, 
    getUserDetailByAddressApi, 
    loginUserByAddressApi, 
    loginUserByUserNameApi, 
    updateProfileApi,
    uploadBannerImageApi,
    uploadProfileImageApi
} from '../services/user';
import { 
    getTokenByUserFailed, 
    getTokenByUserSuccess, 
    getUserDetailByAddress, 
    getUserDetailByAddressFailed, 
    getUserDetailByAddressSuccess, 
    loginUserByAddressFailed, 
    loginUserByAddressSuccess, 
    loginUserByUserNameFailed, 
    loginUserByUserNameSuccess, 
    updateProfileFailed, 
    updateProfileSuccess,
    uploadBannerImageFailed,
    uploadBannerImageSuccess,
    uploadProfileImageFailed,
    uploadProfileImageSuccess
} from '../actions/users';
import store from '../store';

import { toast } from 'react-toastify';

function* loginUserByAddressSaga({ payload }) {
    try{
        const responce = yield call(loginUserByAddressApi, payload)
        // console.log("loginUserByAddressSagaSuccess>>>>>>>>....",responce)
        if(responce){
            if (responce.data.status === 200) {
                localStorage.setItem("chameleon_access_token", responce.data.response)
                 store.dispatch(loginUserByAddressSuccess());
                 store.dispatch(getUserDetailByAddress());
                // console.log("loginUserByAddressSagaSuccess",responce)
            }else {
                store.dispatch(loginUserByAddressFailed(responce.data.message));
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("loginUserByAddressSaga",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('LOGIN_USER_BY_ADDRESS',e)
        // yield put({ type: 'LOGIN_USER_BY_ADDRESS', message: e.message });
    }
}

function* loginUserByUserNameSaga({ payload }) {
    try{
        const responce = yield call(loginUserByUserNameApi, payload)
        console.log("loginUserByUserNameSagaSuccess>>>>>>>>....",responce)
        if(responce){
            if (responce.data.status === 200) {
                localStorage.setItem("chameleon_access_token", responce.data.response)
                 store.dispatch(loginUserByUserNameSuccess({ status: responce.data.status }));
                 store.dispatch(getUserDetailByAddress());
                console.log("loginUserByUserNameSagaSuccess",responce)
            }else {
                store.dispatch(loginUserByUserNameFailed({ status: responce.data.status, message: responce.data.message }));
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("loginUserByUserNameSaga",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('LOGIN_USER_BY_ADDRESS',e)
        // yield put({ type: 'LOGIN_USER_BY_ADDRESS', message: e.message });
    }
}

function* getUserDetailByAddressSaga({ payload }) {
    try{
        const responce = yield call(getUserDetailByAddressApi, payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(getUserDetailByAddressSuccess({status: responce.data.status, userDetails: responce.data.response}));
                console.log("getUserDetailByAddressSagaSuccess",responce)
            }else {
                store.dispatch(getUserDetailByAddressFailed({status: responce.data.status, message: responce.data.message}));
                // console.log("getUserDetailByAddressSaga",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('LOGIN_USER_BY_ADDRESS',e)
        // yield put({ type: 'LOGIN_USER_BY_ADDRESS', message: e.message });
    }
}

function* getTokenByUserSaga({ payload }) {
    try{
        const responce = yield call(getTokenByUserApi, payload)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(getTokenByUserSuccess({status: responce.data.status, chameleonNft: responce.data.response}));
                // console.log("getTokenByUserSagaSuccess",responce.data.status)
            }else {
                store.dispatch(getTokenByUserFailed(responce.data.message));
                // console.log("getTokenByUserSaga",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('LOGIN_USER_BY_ADDRESS',e)
        // yield put({ type: 'LOGIN_USER_BY_ADDRESS', message: e.message });
    }
}

function* updateProfileSaga({ payload }) {
    // console.log("qwerty", payload)
    try{
        const responce = yield call(updateProfileApi, payload.userNewData)
        if(responce){
            if (responce.data.status === 200) {
                store.dispatch(updateProfileSuccess({status: responce.data.status}));
                // console.log("updateProfileSagaSuccess",responce.data.status)
                toast.success(responce.data.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                store.dispatch(getUserDetailByAddressApi(payload.accountNo))
            }else {
                store.dispatch(updateProfileFailed(responce.data.message));
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("updateProfileSaga",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('LOGIN_USER_BY_ADDRESS',e)
        // yield put({ type: 'LOGIN_USER_BY_ADDRESS', message: e.message });
    }
}

function* uploadProfileImageSaga({ payload }) {
    // console.log("qwerty1", payload)
    try{
        const fd = new FormData();
        fd.append('file', payload.file);
        fd.append('userId', payload.userId );
        const responce = yield call(uploadProfileImageApi, fd)
        if(responce){
            // console.log("qwerty1", responce)
            if (responce.data.status === 200) {
                store.dispatch(uploadProfileImageSuccess(responce.data.message));
                // console.log("uploadProfileImageSagaSuccess",responce.data.status)
                toast.success(responce.data.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                store.dispatch(getUserDetailByAddressApi(payload.userId));
            }else {
                store.dispatch(uploadProfileImageFailed(responce.data.message));
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("uploadProfileImageSaga",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('LOGIN_USER_BY_ADDRESS',e)
        // yield put({ type: 'LOGIN_USER_BY_ADDRESS', message: e.message });
    }
}

function* uploadBannerImageSaga({ payload }) {
    // console.log("qwertyBNR1", payload)
    try{
        const fd = new FormData();
        fd.append('file', payload.file);
        fd.append('userId', payload.userId );
        const responce = yield call(uploadBannerImageApi, fd)
        if(responce){
            // console.log("qwertyBNR1", responce)
            if (responce.data.status === 200) {
                store.dispatch(uploadBannerImageSuccess(responce.data.message));
                // console.log("uploadBannerImageSagaSuccess",responce.data.status)
                toast.success(responce.data.message, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                store.dispatch(getUserDetailByAddressApi(payload.userId));
            }else {
                store.dispatch(uploadBannerImageFailed(responce.data.message));
                toast.error( responce.data.message , {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                },);
                // console.log("uploadBannerImageSaga",responce.data.status)
            }
        }
    } catch (e) {
        // console.log('LOGIN_USER_BY_ADDRESS',e)
        // yield put({ type: 'LOGIN_USER_BY_ADDRESS', message: e.message });
    }
}

function* usersSaga() {
    yield takeEvery('LOGIN_USER_BY_ADDRESS', loginUserByAddressSaga );
    yield takeEvery('LOGIN_USER_BY_USERNAME', loginUserByUserNameSaga );
    yield takeEvery('GET_TOKEN_BY_USER', getTokenByUserSaga );
    yield takeEvery('UPDATE_PROFILE', updateProfileSaga );
    yield takeEvery('GET_USER_DETAIL_BY_ADDRESS', getUserDetailByAddressSaga );
    yield takeEvery('UPLOAD_PROFILE_IMAGE', uploadProfileImageSaga );
    yield takeEvery('UPLOAD_BANNER_IMAGE', uploadBannerImageSaga );
}

export default usersSaga;