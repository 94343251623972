import React from 'react';
import Moment from 'react-moment';
import { PulseLoader }  from 'react-spinners';
import './ChamagotchiDetailsTable.css';

function ChamagotchiDetailsTable({ feedArray, loading }) {
    return (
        <div className='px-2 px-sm-4 px-md-5 pb-5'>
            <table class="table table-sm table-bordered">
                <thead>
                    <tr>
                        <th scope="col">Sno.</th>
                        <th scope="col">Feed Time</th>
                        <th scope="col">Last Fed</th>
                        <th scope="col">Next Feed End Time</th>
                        <th scope="col">Age Utilized (Second)</th>
                        <th scope="col">Max Avail. Age (Second)</th>
                    </tr>
                </thead>
                <tbody>
                    { loading && <div className="p-5"><PulseLoader loading={loading} color='#01BFD9'/></div>}
                    {(feedArray && feedArray.length && !loading) ?
                        feedArray?.map((Feed, index) => {
                            return(
                                <tr key={index}>
                                    <th scope="row">{index + 1}</th>
                                    <td>{Feed.feedTime ? <Moment date={Feed.feedTime} format="MM-DD-YYYY HH:mm:ss"/> : "NA"}</td>
                                    <td>{Feed.lastFeeded ? <Moment date={Feed.lastFeeded} format="MM-DD-YYYY HH:mm:ss"/> : "NA"}</td>
                                    <td>{Feed.nextFeedEndTime ? <Moment date={Feed.nextFeedEndTime} format="MM-DD-YYYY HH:mm:ss"/> : "NA"}</td>
                                    <td>{Feed.ageUsed ? Feed.ageUsed : "NA"}</td>
                                    <td>{Feed.initialAge -(index * Feed.ageInterval )}</td>
                                </tr>
                            )
                        })
                    :
                        <tr>
                            <th scope="row">#</th>
                            <td>NA</td>
                            <td>NA</td>
                            <td>NA</td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    )
}

export default ChamagotchiDetailsTable
