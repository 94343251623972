import React, { Fragment } from 'react';
import TimeIcon from "../../assets/Img/time-icon.png";
import MintIcon from "../../assets/Img/mint-icon.png";
import EthIcon from "../../assets/Img/eth-icon.png";
import PolygonIcon from "../../assets/Img/polygon-icon.png";
import Chamagotchi from "../../assets/Img/chamagotchi.png";

function ChamagotchiIntro() {
    return (
        <Fragment>
           <div className="text_content_1">
                <span>CHAMA-WHAT? CHAMAGOTCHI!</span>
                <p className="mb-4" >The Chameleon Collective is bringing back some childhood nostalgia to our project! We want to assure the community that we are continuing to listen to feedback. One of the main comments that we consistently hear from the Chamfam, is utility.</p>
                <p className="" >These are little handheld toys from back in the '90s that were essentially "living beings." You'd have to feed, bathe, and put them to sleep by pressing a button at the appropriate times. Naturally, we're putting our own little spin on it, and simplifying that for our Collective challenge.</p>
            </div>
            <div className="d-flex ">
                <div className="intro_text_container ">
                    <div className="d-flex flex-column align-items-start">
                        <span className="coloredTitle purple_bg mr-auto">THE GOAL</span>
                        <div className="text_content_2 py-1 " >
                            <p className=" mr-auto mb-4" style={{textAlign: "left"}}><b>Keep your Chamagotchi alive the longest!</b> Chamagotchi's will need to be fed through this website with a simple daily, one-click step</p>
                        </div>
                        <span className="coloredTitle blue_bg mr-auto">THE DETAILS</span>
                        <div className="text_content_3" >
                            <div className="d-flex align-items-center">
                                <img src={TimeIcon} alt="" className="money_gole" />
                                <div className="mt-1">
                                    <p className="" style={{textAlign: "left"}} >The minting period will be max 72 hours in which you can purchase your Chamagotchi NFT entry.</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <img src={MintIcon} alt="" className="money_gole" />
                            <div className="mt-1">
                                <p className="" style={{textAlign: "left"}} >There will be no limit (entries) for how many you wish to play with.</p>
                            </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <img src={EthIcon} alt="" className="money_gole" />
                                <div className="mt-1">
                                    <p className="" style={{textAlign: "left"}} >The price is 0.025 ETH for Chameleon holders, and 0.05 ETH for non-Chameleon holders.</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <img src={PolygonIcon} alt="" className="money_gole" />
                                <div className="mt-1">
                                    <p className="" style={{textAlign: "left"}} >In order to ensure the player-costs are kept to a minimum, these will be launched on the Polygon network and airdropped directly into your wallet.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="intro_img position-relative">
                    <img src={Chamagotchi} alt="" className="" />
                </div>
        </div> 
        </Fragment>
    )
}

export default ChamagotchiIntro
