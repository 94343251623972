import * as type from '../types';

const initialState = {
    status: '',
    traits: [],
    chamagotchis: [],
    loading: false,
    creatingChamagotchi: false,
    chamagotchiCreatedStatus: '',
    chamagotchiFeededStatus: '',
    airdropChamagotchiStatus: '',
    ChamagotchiDetails: {},
    ChamagotchiDetailsById: {},
    leaderBoardData: [],
    error: null,
    message: '',
}

export default function chamagotchi(state = initialState, action) {
    switch (action.type) {
        case type.FIND_BY_TRAITS_TYPE:
            return {
                ...state,
                loading: true,
            }
        case type.FIND_BY_TRAITS_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                traits: action.payload.traits,
                status: action.payload.status,
            }
        case type.FIND_BY_TRAITS_TYPE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case type.CREATE_CHAMAGOTCHI:
            return {
                ...state,
                creatingChamagotchi: true,
            }
        case type.CREATE_CHAMAGOTCHI_SUCCESS:
            return {
                ...state,
                creatingChamagotchi: false,
                chamagotchiCreatedStatus: action.payload.status,
                message: action.payload.message,
            }
        case type.CREATE_CHAMAGOTCHI_FAILED:
            return {
                ...state,
                creatingChamagotchi: false,
                error: action.payload,
            }
        case type.FIND_CHAMAGOTCHI_BY_USER:
            return {
                ...state,
                loading: true,
            }
        case type.FIND_CHAMAGOTCHI_BY_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                chamagotchis: action.payload.chamagotchis,
                status: action.payload.status,
            }
        case type.FIND_CHAMAGOTCHI_BY_USER_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case type.CREATE_CHAMAGOTCHI_FEED:
            return {
                ...state,
                loading: true,
            }
        case type.CREATE_CHAMAGOTCHI_FEED_SUCCESS:
            return {
                ...state,
                loading: false,
                chamagotchiFeededStatus: action.payload.status,
            }
        case type.CREATE_CHAMAGOTCHI_FEED_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.message,
                chamagotchiFeededStatus: action.payload.status,
            }
        case type.ON_AFTER_MINT:
            return {
                ...state,
                loading: true,
            }
        case type.ON_AFTER_MINT_SUCCESS:
            return {
                ...state,
                loading: false,
                ChamagotchiDetails: action.payload.ChamagotchiDetails,
                // mintingChamagotchiStatus: action.payload.status,
            }
        case type.ON_AFTER_MINT_FAILED:
                return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case type.ON_AFTER_AIRDROP:
            return {
                ...state,
                loading: true,
                airdropChamagotchiStatus: action.payload.status,
            }
        case type.ON_AFTER_AIRDROP_SUCCESS:
            return {
                ...state,
                loading: false,
                airdropChamagotchiStatus: action.payload.status,
            }
        case type.ON_AFTER_AIRDROP_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case type.GET_CHAMAGOTCHI_DETAILS_BY_ID:
            return {
                ...state,
                loading: true,
            }
        case type.GET_CHAMAGOTCHI_DETAILS_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                ChamagotchiDetailsById: action.payload.ChamagotchiDetailsById
            }
        case type.GET_CHAMAGOTCHI_DETAILS_BY_ID_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case type.GET_LEADER_BOARD_DATA:
            return {
                ...state,
                loading: true,
            }
        case type.GET_LEADER_BOARD_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                leaderBoardData: action.payload.leaderBoardData
            }
        case type.GET_LEADER_BOARD_DATA_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case type.RESET_CHAMAGOTCHI:
            return {
                ...state,
                message: '',
                chamagotchiCreatedStatus: '',
                chamagotchiFeededStatus: '',
                airdropChamagotchiStatus: '',
                ChamagotchiDetails: '',
                error: '',
            }
        default:
            return state;
    }
}
