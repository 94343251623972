import React from 'react';
import Header from '../../components/Header/Header';
import Merch from '../../components/Merch/Merch';
import MerchBg1 from '../../assets/Img/merch.jpg';
import Founder from '../../components/Founder/Founder';
import Footer from '../../components/Footer/Footer';
import Roadmap from '../../components/Roadmap/Roadmap';
import MintingSection from '../../components/MintingSection/MintingSection';

function OldHome() {
    return (
        <div>
            <Header Intro="We’re a collection of 10,000 almost truly wild chameleons, evolved out of 220+ unique assets." btnName="MINT NOW!"/>
            <Roadmap />
            <MintingSection />
            <Merch merchBg={MerchBg1} btnContent="Shop Now" />
            <Founder />
            <Footer />
        </div>
    )
}

export default OldHome
