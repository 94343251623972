import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FadeLoader }  from 'react-spinners';
import { FaChevronLeft, FaTimesCircle, FaTrophy } from "react-icons/fa";
import { FcInfo, FcSearch } from "react-icons/fc";
import Footer from '../../components/Footer/Footer';
import LeaderboardComponent from '../../components/LeaderboardComponent/LeaderboardComponent';
import { getLeaderBoardData, resetChamagotchi } from '../../redux/actions/chamagotchi';
import './ChamagotchiLeaderBoard.css';

function ChamagotchiLeaderBoard({ setActivePage }) {
    const [tokenId, setTokenId] = useState('');
    const [fakeCurrentDate, setFakeCurrentDate] = useState(new Date()) // default value can be anything you want

    const history = useHistory();

    const dispatch = useDispatch();
    const leaderBoardData = useSelector(state => state.chamagotchi.leaderBoardData);
    const loading = useSelector(state => state.chamagotchi.loading);


    useEffect(() => {
        setTimeout(() => setFakeCurrentDate(new Date()), 60000)
    }, [fakeCurrentDate])


    useEffect(() => {
        setActivePage('leaderboard');
        dispatch(getLeaderBoardData(''));
        return () => {
            setActivePage('');
            dispatch(resetChamagotchi());
        }
    }, [fakeCurrentDate]);

    return (
        <Fragment>
            <div className='app_container bg_white p-3 pt-sm-4 d-flex flex-column justify-content-start align-items-center'>
                <div className='leaderboard d-flex flex-column justify-content-start align-items-center'>
                    <div className="leaderboard_header w-100 d-flex justify-content-center align-items-end p-1">Chamagotchi <FaTrophy className='mx-2' color='gold' size={50} /> Leaderboard</div>
                    <div className='leaderboard_component d-flex justify-content-between align-items-center m-2 px-2'>
                        <div className="leaderboard_nftImg mr-2">
                            <img src='img/blank-chamagotchi.jpg' alt="blank-chamagotchi.jpg" className="" /> 
                        </div>
                        <div className="leaderboard_search w-100 h-100 py-1">
                            <input type="text" className="w-100 h-100 rounded px-3"  name='tokenId' placeholder='search' value={tokenId} onChange={(e) => setTokenId(e.target.value)}/>
                        </div>
                        <div className="d-flex justify-content-between align-items-center ml-2">
                            <div className="leaderboard_medal pl-1">
                                {(tokenId)? <FcSearch size={40} onClick={() => dispatch(getLeaderBoardData(tokenId))}/> : <FcSearch size={40}/> }
                            </div>
                        </div>
                    </div>
                    <span className="text-info"><FcInfo size={20}/> Updated 5 minute ago</span>
                    { loading && <div className="w-100 h-100 d-flex justify-content-center align-items-center"><FadeLoader loading={loading} color='#01BFD9'/></div>}
                    {(leaderBoardData && !loading) ?
                        leaderBoardData?.map((leader, index) => {
                            return(
                                <LeaderboardComponent 
                                    key={leader.index}
                                    nftImg={leader.imagePath}
                                    userName={leader.userName}
                                    tokanId={leader.chamagotchiTokenId}
                                    accountCode={leader.accountCode}
                                    nextFeedEndTime={leader.nextFeedEndTime}
                                    rank={leader.rank}
                                    deadTime={leader.deadTime}
                                />
                            )
                        })
                        :
                        null
                    }
                    {((leaderBoardData.length <=1) && !loading) ?
                        <button className="btn btn-primary btn-sm mt-2 rounded-full" onClick={() => {dispatch(getLeaderBoardData('')); setTokenId('');}}> 
                            <FaTimesCircle size={50} color='#fff'/>
                        </button>
                        : 
                        null
                    }
                </div>
                <button className="btn btn-primary btn-sm mt-2" onClick={() => history.goBack()}> 
                    <FaChevronLeft size={16} color='#fff'/>
                    Back
                </button>
            </div>
            <Footer />
        </Fragment>
    )
}

export default ChamagotchiLeaderBoard
