import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { findByTraitType } from '../../../redux/actions/chamagotchi';
import { GridLoader } from 'react-spinners';
import '../DesignYourPetAssets.css';

function Characters({ selectedCharacter, setSelectedCharacter, setSelectedCharacterId }) {
    const [traitType, setTraitType] = useState("Character")

    const dispatch = useDispatch();
    const traits = useSelector(state => state.chamagotchi.traits);
    const loading = useSelector(state => state.chamagotchi.loading);
    const error = useSelector(state => state.chamagotchi.error);

    useEffect(() => {
        dispatch(findByTraitType(traitType));
    }, []);

    return (
        <div className="thumbnails_wrapper d-flex flex-wrap p-2 p-sm-4">
            {(loading) ?
            <div className="d-flex justify-content-center align-items-end w-100 p-5">
                <GridLoader loading={loading} size={30} color='#e486a1'/>
            </div>
                :
                !loading && traits.length && traits?.map((thumb, index) => { 
                    return(
                        thumb.image && thumb.thumbnail &&
                        <div className='thumbnanil d-flex flex-column'>
                            <img 
                                key={index}
                                src={`img/pet-design-assets/CHARACTERS/THUMB/${thumb.thumbnail}`} 
                                alt="img-url" 
                                className={` ${(`img/pet-design-assets/CHARACTERS/${thumb.image}` === selectedCharacter) ?  "Selected_thumbnail" : ""}`} 
                                onClick={() => {
                                    setSelectedCharacter(`img/pet-design-assets/CHARACTERS/${thumb.image}`)
                                    setSelectedCharacterId(thumb.traitTypeId);
                                }}
                            />
                            <span className='character_name'> {thumb.traitName}</span>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default Characters
