export const base_url = 'http://172.104.50.53:6060'; // UAT url
export const base_url_user = base_url+'/api/user';
export const base_url_token = base_url+'/api/token';
export const base_url_chamagotchi = base_url+'/api/chamagotchi';
export const base_url_traitType = base_url+'/api/traitType';

export const end_points = {
    login_user_by_address : '/api/auth/loginUserByAddress',
    login_user_by_username : '/api/auth/login',
    get_user_detail_by_address : '/getUserDetailByAddress',
    get_token_by_user: '/getTokenByUser',
    update_profile: '/updateProfile',
    upload_profile_image: '/uploadProfileImage',
    upload_banner_image: '/uploadBannerImage',
    Chamagotchi_find_by_trait_type: '/findByTraitType',
    create_chamagotchi: '/createChamagotchi',
    find_chamagotchi_by_user: '/findByUser',
    create_chamagotchi_feed: '/api/chamagotchiFeed/createChamagotchiFeed',
    on_after_mint: '/api/chamagotchi/onAfterMint',
    on_after_airdrop: '/api/chamagotchi/onAfterAirdrop',
    get_chamagotchi_details: '/api/chamagotchi/getChamagtochiDetailForUser',
    get_leader_board_data: '/api/public/getLeaderBoardData',
}
