import React, { Fragment, useState } from 'react';
import DesignYourPetMenuBtn from './DesignYourPetMenuBtn/DesignYourPetMenuBtn';
import Checkmark from '../../assets/Img/checkmark.png';
import './DesignYourPetMenu.css';

function DesignYourPetMenu( { 
    activeOption, 
    setActiveOption, 
    selectedCharacter,
    selectedTopFrame,
    selectedBottomFrame,
    selectedScreenFrame,
    selectedHornTail,
    selectedButtons,
    selectedChain,
    selectedScreenColor,
    selectedBackground, }) {
    const [traitType] = useState([
        { id: 1, Title: "Background" },
        { id: 2, Title: "Top Frame" },
        { id: 3, Title: "Bottom Frame" },
        { id: 4, Title: "Screen Frame" },
        { id: 7, Title: "Chain" },
        { id: 6, Title: "Buttons" },
        { id: 5, Title: "Horn / Tail" },
        { id: 8, Title: "Screen Color" },
        { id: 9, Title: "character" },
    ]);

    return (
        <div className="design_menu d-flex flex-column px-2 py-4 position-relative">
            {traitType?.map(( option, index ) => {
                return(
                    <div key={index} className="position-relative">
                        <DesignYourPetMenuBtn 
                            key={index} 
                            Title={option.Title} 
                            activeOption={activeOption} 
                            setActiveOption={setActiveOption}
                        />
                        <img 
                            src={Checkmark} 
                            alt="" 
                            className={`
                                ${(option.Title === "character" && selectedCharacter) ? "checkmark": 
                                (option.Title === "Top Frame" && selectedTopFrame) ? "checkmark": 
                                (option.Title === "Bottom Frame" && selectedBottomFrame) ? "checkmark": 
                                (option.Title === "Screen Frame" && selectedScreenFrame) ? "checkmark": 
                                (option.Title === "Horn / Tail" && selectedHornTail) ? "checkmark": 
                                (option.Title === "Buttons" && selectedButtons) ? "checkmark": 
                                (option.Title === "Chain" && selectedChain) ? "checkmark": 
                                (option.Title === "Screen Color" && selectedScreenColor) ? "checkmark": 
                                (option.Title === "Background" && selectedBackground) ? "checkmark": "d-none"}
                            `} 
                        />
                    </div>
                )})
            }
        </div>
    )
}

export default DesignYourPetMenu
