import axios from "axios";
import { base_url, base_url_chamagotchi, base_url_traitType, end_points } from "../../constant";

function getAxios(method, url, data, isAccessToken) {
    if(isAccessToken) {
        return axios({
            method: method,
            url: url,
            data: data,
            headers: { "Content-Type": "application/json", "Authorization": "Bearer "+localStorage.getItem("chameleon_access_token") },
        })
    }else {
        return axios({
            method: method,
            url: url,
            data: data,
            headers: { "Content-Type": "application/json" },
        })
    }
}

export function findByTraitTypeApi( traitType ) {
    return getAxios( 'get', base_url_traitType + end_points.Chamagotchi_find_by_trait_type + '?traitType=' + traitType, null, true)
}

export function createChamagotchiApi( payload ) {
    return getAxios( 'post', base_url_chamagotchi + end_points.create_chamagotchi, payload, true)
}

export function findChamagotchiByUserApi( userId ) {
    return getAxios( 'get', base_url_chamagotchi + end_points.find_chamagotchi_by_user + '?userId=' + userId, null, true)
}

export function createChamagotchiFeedApi( payload ) {
    return axios({
        method: 'get',
        url: base_url + end_points.create_chamagotchi_feed + '?chamagotchiId=' + payload.chamagotchiId,
        data: null,
        headers: { "Content-Type": "application/json", "Authorization": "Bearer "+localStorage.getItem("chameleon_access_token"), "g-recaptcha-response" : payload.captchaResponse },
    })
}

export function onAfterMintApi( payload ) {
    return getAxios( 'post', base_url + end_points.on_after_mint, payload, true)
}

export function onAfterairdropApi( payload ) {
    return getAxios( 'post', base_url + end_points.on_after_airdrop, payload, true)
}

export function getChamagotchiDetailsByIdApi( chamagotchiId ) {
    return getAxios( 'get', base_url + end_points.get_chamagotchi_details + '?chamagotchiId=' + chamagotchiId, null, true)
}

export function getLeaderBoardDataApi( tokenId ) {
    return getAxios( 'get', base_url + end_points.get_leader_board_data + '?tokenId=' + tokenId, null, false)
}
