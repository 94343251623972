import axios from "axios";
import { base_url, base_url_token, base_url_user, end_points } from "../../constant";

function getAxios(method, url, data, isAccessToken) {
    if(isAccessToken) {
        return axios({
            method: method,
            url: url,
            data: data,
            headers: { "Content-Type": "application/json", "Authorization": "Bearer "+localStorage.getItem("chameleon_access_token") },
        })
    }else {
        return axios({
            method: method,
            url: url,
            data: data,
            headers: { "Content-Type": "application/json" },
        })
    }
}

export function loginUserByAddressApi( payload ) {
    return getAxios( 'post', base_url + end_points.login_user_by_address, payload, false)
}

export function loginUserByUserNameApi( payload ) {
    return getAxios( 'post', base_url + end_points.login_user_by_username, payload, false)
}

export function getUserDetailByAddressApi( address ) {
    return getAxios( 'get', base_url_user + end_points.get_user_detail_by_address + '?address=' + address, null, true)
}

export function getTokenByUserApi( userId ) {
    return getAxios( 'get', base_url_token + end_points.get_token_by_user + '?userId=' + userId, null, true)
}

export function updateProfileApi( payload ) {
    return getAxios( 'post', base_url_user + end_points.update_profile, payload, true)
}

export function uploadProfileImageApi( payload ) {
    return getAxios( 'post', base_url_user + end_points.upload_profile_image, payload, true)
}

export function uploadBannerImageApi( payload ) {
    return getAxios( 'post', base_url_user + end_points.upload_banner_image, payload, true)
}