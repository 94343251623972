import React from 'react';
import './DesignYourPetMenuBtn.css';

function DesignYourPetMenuBtn({
    Title,
    activeOption,
    setActiveOption
}) {
    return (
        <div className={`${(activeOption === Title ) ? "active_menu_btn" : "menu_btn"}`} onClick={() => setActiveOption(Title)}>
            {Title}
        </div>
    )
}

export default DesignYourPetMenuBtn
