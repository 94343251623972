import React, { useState } from 'react';
import './NftCard.css';

function NftCard({ nftImgUrl, tokanNo, index, isShowMore, setShowPopup, setPopupNftImgUrl, setPopupNftTokanNo, }) {
    const [isFav, setIsFav] = useState(false);

    return (
        <div 
            className={`${(!isShowMore) ? ( index >= 5 )? "d-none" : "nft_card d-flex flex-column p-2" : "nft_card d-flex flex-column p-2"}`}
        >
            <img 
                src={nftImgUrl} 
                alt="nft-img" 
                onClick={() => {
                    setShowPopup(true);
                    setPopupNftImgUrl(nftImgUrl)
                    setPopupNftTokanNo(tokanNo);
                }}
            />
            <div className="d-flex justify-content-between align-item-center p-2">
                <span className="tokan_no">{tokanNo}</span>
                <span className="add_to_fav" onClick={() => setIsFav(!isFav)}>
                    {(!isFav) ?
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-20 w-20" fill="none" viewBox="0 0 24 24" stroke="#808080">
                            <path stroke-linecap="round" strokeLinejoin="round" stroke-width="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                        </svg>
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-20 w-20" viewBox="0 0 20 20" fill="red">
                            <path fill-rule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clip-rule="evenodd" />
                        </svg>
                    }
                </span>
            </div>
        </div>
    )
}

export default NftCard
