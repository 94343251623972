import React from 'react';
import './DesignYourPetWorkdesk.css';

function DesignYourPetWorkdesk({ 
    selectedCharacter,
    selectedTopFrame,
    selectedBottomFrame,
    selectedScreenFrame,
    selectedHornTail,
    selectedButtons,
    selectedChain,
    selectedScreenColor,
    selectedBackground,
}) {
    return (
        <div className="pet_Assembly_wrapper mt-4">
            <img src={"img/blank-chamagotchi.jpg"} alt="" className="placeholder_chamagotchi w-100 h-100" />
            <img src={selectedCharacter} alt="" className="pet character" />
            <img src={selectedTopFrame} alt="" className="pet top_frame" />
            <img src={selectedBottomFrame} alt="" className="pet bottom_frame" />
            <img src={selectedScreenFrame} alt="" className="pet screen_frame" />
            <img src={selectedHornTail} alt="" className="pet horn_tail" />
            <img src={selectedButtons} alt="" className="pet buttons" />
            <img src={selectedChain} alt="" className="pet chain" />
            <img src={selectedScreenColor} alt="" className="pet screen_color" />
            <img src={selectedBackground} alt="" className="pet background rounded" />
        </div>
    )
}

export default DesignYourPetWorkdesk
