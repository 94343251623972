import React from 'react';
import { GrClose } from "react-icons/gr";
import { FaDiscord} from 'react-icons/fa';
import OpenseaIconDark from '../../../assets/Img/opensea-icon-dark.png';
import './SideNav.css';

function SideNav({ setIsSidebarOpen, setIsAGuestUser }) {
    return (
        <div>
            <nav className="side_navbar">
                <span className="navClose d-flex w-100 justify-content-end p-4" >
                    <div className="d-flex justify-content-center align-items-center">
                        <a href="https://t.co/7yjP8YT5Eb?amp=1" title="Discord" target="_blank" className="nav_icons">
                            <FaDiscord size={40} color={"#000"}/>
                        </a>
                        <a href="https://opensea.io/collection/chameleon-collective" target="_blank" className="nav_icons mr-5">
                            <img src={OpenseaIconDark} alt="" />
                        </a>
                    </div>
                    <GrClose size={30} onClick={() => setIsSidebarOpen(false)}/>
                </span>
                <ul className="navbar-nav">
                    <li><a href="/" onClick={() => setIsAGuestUser(true)}>home</a></li>
                    <li className='d-block d-md-none'><a href="/chamagotchi" onClick={() => setIsAGuestUser(true)}>chamagotchi</a></li>
                    <li><a href="/dashboard" onClick={() => setIsAGuestUser(true)}>dashboard</a></li>
                    <li><a href="/leaderboard" onClick={() => setIsAGuestUser(true)}>Leaderboard</a></li>
                    <li><a href="/0xChams" onClick={() => setIsAGuestUser(true)}>0xChams</a></li>
                    {/* <li className='d-block d-md-none'><a href="/design" onClick={() => setIsAGuestUser(true)}>design</a></li> */}
                    <li className='d-block d-md-none'><a href="/faq" onClick={() => setIsAGuestUser(true)}>faq</a></li>
                    <li><a href="/signup" onClick={() => setIsAGuestUser(true)}>login</a></li>
                </ul>
            </nav>
        </div>
    )
}

export default SideNav
