import React, { Fragment, useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { SiChatbot } from "react-icons/si";
import Footer from '../../components/Footer/Footer';
import './Faq.css';

function Faq({ setActivePage }) {
    const [faqAndAns] = useState([
        { 
            id: 1, 
            controlId: 'One', 
            question: 'When is mint?', 
            answer: 'Minting starts January 7 and remains open for 72 hours.' 
        },
        { 
            id: 2, 
            controlId: 'Two', 
            question: 'Do you need ETH to buy a Chamagotchi?', 
            answer: 'Yes. The price is .025 for Chameleon holders, .035 for partner project holders, and .05 for everyone else.' 
        },
        { 
            id: 3, 
            controlId: 'Three', 
            question: 'Can I purchase on my phone, or computer?', 
            answer: 'Both. Everything will be done through our website — ',
            answerLink: 'https://chameleoncollective.io'
        },
        { 
            id: 4, 
            controlId: 'Four', 
            question: 'Any logins need to be created?', 
            answer: 'You’ll login using your Metamask digital wallet and create a profile.' 
        },
        { 
            id: 5, 
            controlId: 'Five', 
            question: 'Will gas fees be high?', 
            answer: 'No, we’re using the Polygon network and dropping the NFT directly to your wallet, gas should be~$8 USD.' 
        },
        { 
            id: 6, 
            controlId: 'Six', 
            question: 'How does Chamagotchi work for Cham holders? For non-Cham holders?', 
            answer: 'If you hold a Chameleon in your wallet you will automatically get 50% off the mint price and if you keep your pet alive the longest you will get an increased percentage of the winnings.' 
        },
        { 
            id: 7, 
            controlId: 'Seven', 
            question: 'How many can you mint?', 
            answer: 'As many as you want! But only for 72 hours.' 
        },
        { 
            id: 8, 
            controlId: 'Eight', 
            question: 'Is there a WL?', 
            answer: 'Nope, it’s open to anyone and everyone and there’s no mint limit but once the 72 hours has gone by, minting closes and all the players are locked in.' 
        },
        { 
            id: 9, 
            controlId: 'Nine', 
            question: 'Best wallet to use?',
            answer: 'Metamask' 
        },
        { 
            id: 10, 
            controlId: 'Ten', 
            question: 'How do you feed it, is it free to feed?', 
            answer: 'Once you login you’ll be taken to a dashboard where you’ll see all the Chameleons in your wallet as well as all your Chamagotchis. Underneath your Chamagotchi will be a timer that shows you how long you have before your pet dies and a button that says FEED. Click that button to feed it, it’s a free, one-click process and will reset your feed timer.' ,
            answerImg: [
                { id: 1, ImgName:'answer-img-1' },
            ],
            answerLine2: 'But be careful — each time you click FEED your timer decreases by ~15 minutes!',
        },
        { 
            id: 11, 
            controlId: 'Eleven', 
            question: 'Do you have to connect your wallet to feed it?', 
            answer: 'Yes, you’ll need to login to your account each time to feed it.' 
        },
        { 
            id: 12, 
            controlId: 'Twelve', 
            question: 'How long will the contest last?', 
            answer: 'Until all but the final 5, 10, 15, 20, etc. are dead. For every 1,000 minted 5 more winners are added to the prize pool. We’ve calculated that the longest this could last is about 2 months.' 
        },
        { 
            id: 13, 
            controlId: 'Thirteen', 
            question: 'Can you sell your Chamagotchi on OS like the Chameleons?', 
            answer: 'Yes! Your Chamagotchi is an NFT and can be traded as such. You can sell it once it dies, or if yours is still alive someone can put an offer on it and buy it from you in order to keep playing. So could you have a farm of Chamagotchis to keep alive and sell once others have died? You sure can!' 
        },
        { 
            id: 14, 
            controlId: 'Fourteen', 
            question: 'Do you need a Chameleon to mint a Chamagotchi?', 
            answer: 'No you don’t. You can mint at .05 ETH if you don’t hold a Chameleon, or .035 ETH if you hold a partner project.' 
        },
        { 
            id: 15, 
            controlId: 'Fifteen', 
            question: 'How many winners are there?', 
            answer: 'Minimum of 5 winners, and for every 1,000 minted another 5 winners are added.' 
        },
        { 
            id: 16, 
            controlId: 'Sixteen', 
            question: 'What happens to the Chamagotchi after the contest is over or if yours dies? Can it still be used?', 
            answer: 'It’s an NFT so you can buy, sell, trade as you’d like. The winners will stay alive forever and their NFT will be updated to reflect this.' 
        },
        { 
            id: 17, 
            controlId: 'Seventeen', 
            question: 'What do you win?', 
            answer: 'For every 100 minted 1 ETH will be added to the pot and the winners to keep their pet alive the longest will split the pot. There’s a 5 ETH minimum pot, with a minimum of 5 winners — so at the very least you would win 1 ETH. So let’s say 5,000 are minted — that would make a 50 ETH pot split 25 ways. So 2 ETH per person or more if you hold a Chameleon!' 
        },
    ]);

    useEffect(() => {
        setActivePage('faq');
        return () => {
            setActivePage('');
        }
    }, []);

    return (
        <div className='app_container bg_white'>
            <div className="p-5">
            <h1 className="faq_heading text-uppercase mb-5">frequently &nbsp; asked &nbsp; questions</h1>
                <Accordion defaultActiveKey={['0']} alwaysOpen>
                    {faqAndAns?.map((faq) => {
                        return(
                            <Accordion.Item eventKey={faq.id} key={faq.id}>
                                <Accordion.Header><div className='faq_que'>{faq.id}. {faq.question}</div></Accordion.Header>
                                <Accordion.Body>
                                    <div className='faq_ans text-left pl-2'><SiChatbot size={24} color='#c3dd05'/> 
                                        &nbsp;&nbsp;&nbsp;
                                        {faq.answer}
                                        {(faq.answerLink && faq.answerLink) ? <a className='faq_link' href={faq.answerLink} target='_blank'>{faq.answerLink}</a> : null}
                                    </div>
                                    {(faq.answerImg) ?
                                        faq.answerImg?.map((faqImg, index) => {
                                            return(
                                                <div className='faq_ans text-left pl-4 d-flex m-4' key={index}>
                                                    <img src={`img/${faqImg.ImgName}.png`} alt={`answer-img-${faqImg.id}`} className='faq_ans_img'/>
                                                    <sapn className="p-2"></sapn>
                                                </div>
                                            )
                                        })
                                        :
                                        null
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                            )
                        })
                    }
                    <Accordion.Item eventKey={18}>
                        <Accordion.Header><div className='faq_que'>18. Step-by-step Mint Process</div></Accordion.Header>
                        <Accordion.Body>
                            <div className='faq_ans text-left pl-4 d-flex flex-column'>
                                1. You’ll login using your digital wallet
                                <sapn className="p-2"></sapn>
                                <img src="img/step-img-1.png" alt="step-img-1" className='faq_ans_img'/>
                                <sapn className="p-1"></sapn>
                                2. Your Chameleon Collective wallet will pop up, if you own any Chameleons you’ll see them. You can click ‘Create your own Chamagotchi now’ to get started. You can also click “Design-NFT” to create one, or go to the “Chamagotchi”page to learn more…
                                <sapn className="p-2"></sapn>
                                <img src="img/step-img-2.png" alt="step-img-2" className="faq_ans_img" />
                                <sapn className="p-4"></sapn>
                                <img src="img/step-img-3.png" alt="step-img-3" className="faq_ans_img" />
                                <sapn className="p-1"></sapn>
                                3. You’ll then design your Chamagotchi! Working down the left menu you’ll customize the frames, buttons, screen, chain, background and character.
                                <sapn className="p-2"></sapn>
                                <img src="img/step-img-4.png" alt="step-img-4" className="faq_ans_img" />
                                <sapn className="p-1"></sapn>
                                4.Once it’s created you’ll be able to feed your Chamagotchi directly from the dashboard…
                                <sapn className="p-2"></sapn>
                                <img src="img/step-img-5.png" alt="step-img-5" className="faq_ans_img" />
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={19}>
                        <Accordion.Header><div className='faq_que'>19. Who are the listed partners at the discounted rate?</div></Accordion.Header>
                        <Accordion.Body>
                            <div className='faq_ans text-left pl-4 d-flex flex-column'>
                                <sapn className="p-2"></sapn>
                                <img src="img/answer-img-2.png" alt="img/answer-img-2" className='faq_ans_img'/>
                                <sapn className="p-2"></sapn>
                                <img src="img/answer-img-3.png" alt="img/answer-img-3" className='faq_ans_img'/>
                                <sapn className="p-2"></sapn>
                                <img src="img/answer-img-4.png" alt="img/answer-img-4" className='faq_ans_img'/>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <Footer />
        </div>
    )
}

export default Faq
