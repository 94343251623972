import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import { loginUserByAddress, loginUserByUserName } from '../../redux/actions/users';
import MetamaskLogo from "../../assets/Img/MetaMask.png";
import { toast } from 'react-toastify';
import { PulseLoader } from 'react-spinners';
import './Signup.css';

function Signup({ accountNo, setAccountNo, setUserId, setActivePage, setIsAGuestUser }) {
    const [bgImgUrlThin] = useState('img/bg-thin.png');
    const [isMetaMaskConnected, setIsMetaMaskConnected] = useState(false);
    const [isLoginByUserPass, setIsLoginByUserPass] = useState(false);
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [userNameReqMsg, setUserNameReqMsg] = useState('');
    const [passwordReqMsg, setPasswordReqMsg] = useState('');
    const [showPassword, setShowPassword] = useState('');
    const history = useHistory();

    const dispatch = useDispatch();
    const status = useSelector(state => state.users.loginStatus);
    const loginByUserNameStatus = useSelector(state => state.users.loginByUserNameStatus);
    const user = useSelector(state => state.users.user);
    const loading = useSelector(state => state.users.loading);
    const error = useSelector(state => state.users.error);

    useEffect(async (e) => {
            const accounts = await window.ethereum.enable();
            setAccountNo(accounts[0])
            if (accounts[0]) {
                setIsMetaMaskConnected(true);
            }else {
                setIsMetaMaskConnected(false)
            }
    }, []);

    useEffect(() => {
        if(status === 200) {
            if(user && !user.userName && !user.email) {
                history.push('/edit-profile');
            }else {
                history.push('/dashboard');
            }
        }else {
            // alert("something went wrong please cheack your eth Address or reconnect");
            return
        }
    }, [status]);
    
    useEffect(() => {
        if(userName && password) {
            setUserNameReqMsg('');
            setPasswordReqMsg('');
        } else if(userName && !password) {
            setUserNameReqMsg('');
        } else if(!userName && password) {
            setPasswordReqMsg('');
        }else {
            return
        }
    }, [ userName, password ]);

    useEffect(() => {
        setActivePage('signup');
        return () => {
            setActivePage('');
        }
    }, []);
    
    useEffect(() => {
        setUserId(user.userId)
    }, [user]);

    useEffect(() => {
        if(localStorage.getItem("chameleon_access_token")) {
            dispatch(loginUserByAddress({ "address": accountNo }));
        }
    }, [])

    return (
        <Fragment>
            {(!isLoginByUserPass) ?
                <Fragment>
                    <div className='app_container bg_white'>
                        <div className="signup_wrapper d-flex flex-column justify-content-center align-items-center position-relative">
                            <div className="signup_card position-relative">
                                <img src={bgImgUrlThin} alt="" className="w-100 signup_top_img position-relative" />
                                <span className="signup_top_gradint position-absolute"></span>
                                <h1 className='signup_title pt-4 text-uppercase mb-0'>LOGIN</h1>
                                <p className="signup_text pb-5 ">Using your digital wallet. You'll be able to see all your <br /> Chameleon Collective NFTs, $KICKS, achievements, drops and reward</p>
                                <input 
                                    type="text" 
                                    placeholder='Ethereum Address' 
                                    value={accountNo}
                                    onClick={() => {
                                        toast.info('Please fatch your account from MetaMask by clicking following MetaMask icon...', {
                                            position: "bottom-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: false,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                        },);
                                    }} 
                                />
                                <span 
                                    className="reconnect_to_mm position-absolute"
                                    data-tooltip="Connect To MetaMask"
                                    onClick={async (e) => {
                                        const accounts = await window.ethereum.enable();
                                        setAccountNo(accounts[0])
                                        if (accounts[0]) {
                                            setIsMetaMaskConnected(true);
                                        }else {
                                            toast.info('Please set up your MetaMask plugin to proceed...', {
                                                position: "bottom-right",
                                                autoClose: 5000,
                                                hideProgressBar: false,
                                                closeOnClick: false,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                            },);
                                            setIsMetaMaskConnected(false);
                                        }
                                    }}
                                >
                                    <div className="w-100 h-100 d-flex justify-content-between align-items-center">
                                        <img src={MetamaskLogo} alt="" className="mx-1" />
                                        MetaMask 
                                    </div>
                                </span>
                                {(isMetaMaskConnected) ?
                                    (!loading)?
                                        <button 
                                            className="mt-4 mt-sm-5 mb-2" 
                                            onClick={() => {
                                                dispatch(loginUserByAddress({ "address": accountNo }));
                                            }} 
                                        >
                                            Login
                                        </button>
                                        :
                                        // loading-spinner
                                        <button className="mt-4 mt-sm-5 mb-2" >
                                            <PulseLoader loading={true} color='#c3dd05'/>
                                        </button>
                                    :
                                    <button 
                                        className="mt-4 mt-sm-5 mb-2" 
                                        onClick={() => {
                                            toast.info('Please set up your MetaMask plugin to proceed...', {
                                                position: "bottom-right",
                                                autoClose: 5000,
                                                hideProgressBar: false,
                                                closeOnClick: false,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                            },);
                                        }} 
                                    >
                                        Login
                                    </button>
                                }
                                <br />
                                <div className="login_link">
                                    <a href="#" className="" onClick={() => setIsLoginByUserPass(!isLoginByUserPass)}>login with user name and password?</a>
                                </div>
                                <buttton className="btn btn-outline-warning my-2" onClick={() => {setIsAGuestUser(true); history.push('/'); setActivePage('home')}}>
                                    <u>{'Skip for now >>>'} </u>
                                </buttton>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </Fragment>
            :
            <Fragment>
                <div className='app_container bg_white'>
                    <div className="signup_wrapper d-flex flex-column justify-content-center align-items-center position-relative">
                        <div className="signup_card position-relative">
                            <img src={bgImgUrlThin} alt="" className="w-100 signup_top_img position-relative" />
                            <span className="signup_top_gradint position-absolute"></span>
                            <h1 className='signup_title py-5 text-uppercase'>Login</h1>
                            <input 
                                type="text" 
                                placeholder='User Name' 
                                value={userName}
                                onChange={(e) => {setUserName(e.target.value)}} 
                                className='login_input'
                            />
                            {userNameReqMsg ? <span className='required_warn_login d-flex justify-content-end text-danger '>{userNameReqMsg}</span>:null}
                            <input 
                                type={showPassword ? "text" : "Password"} 
                                placeholder='Password' 
                                value={password}
                                onChange={(e) => {setPassword(e.target.value)}} 
                                visible
                                className='login_input password_input mt-3'
                            />
                            <span 
                                className="toggel_pass position-absolute"
                                data-tooltip="Connect To MetaMask"
                            >
                                {(showPassword) ? 
                                    <svg onClick={() => setShowPassword(!showPassword)} xmlns="http://www.w3.org/2000/svg" class="h-20 w-20" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                                        <path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd" />
                                    </svg>
                                    :
                                    <svg onClick={() => setShowPassword(!showPassword)} xmlns="http://www.w3.org/2000/svg" class="h-20 w-20" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" clip-rule="evenodd" />
                                        <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
                                    </svg>
                                }
                            </span>
                            {passwordReqMsg ? <span className='required_warn_login d-flex justify-content-end text-danger'>{passwordReqMsg}</span>:null}
                            {(!userName && !password) ?
                                <button 
                                    className="mt-4 mt-sm-5 mb-2" 
                                    onClick={() => {
                                        setUserNameReqMsg("* User Name Required")
                                        setPasswordReqMsg("* Password Required")
                                    }}
                                >
                                   Login
                                </button>
                                : (!userName && password) ?
                                    <button 
                                        className="mt-4 mt-sm-5 mb-2" 
                                        onClick={() => {
                                            setUserNameReqMsg("* User Name Required")
                                        }}
                                    >
                                       Login
                                    </button>
                                : (!password && userName) ?
                                    <button 
                                        className="mt-4 mt-sm-5 mb-2" 
                                        onClick={() => {
                                            setPasswordReqMsg("* Password Required")
                                        }}
                                    >
                                       Login
                                    </button>
                                : (!loading)?
                                <button 
                                    className="mt-4 mt-sm-5 mb-2" 
                                    onClick={() => {
                                        dispatch(loginUserByUserName({ username: userName, password: password }))
                                    }} 
                                >
                                   Login
                                </button>
                                :
                                // loading-spinner
                                <button className="mt-4 mt-sm-5 mb-2" >
                                    <PulseLoader loading={true} color='#c3dd05'/>
                                </button>
                            }
                            <br />
                            <div className="login_link">
                                <a href="#" className="" onClick={() => setIsLoginByUserPass(!isLoginByUserPass)}>login with MetaMask ?</a>
                            </div>
                            <buttton className="btn btn-outline-warning my-2" onClick={() => {setIsAGuestUser(true); history.push('/'); setActivePage('home')}}>
                                <u>{'Skip for now >>>'} </u>
                            </buttton>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
            }
        </Fragment>
    )
}

export default Signup
