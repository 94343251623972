import { combineReducers } from "redux";
import chamagotchi from "./chamagotchi";
import users from "./users";

const appReducer = combineReducers({
    users: users,
    chamagotchi: chamagotchi,
  });
  
const rootReducers = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        state = undefined;
        localStorage.removeItem("chameleon_access_token")
    }
    return appReducer(state, action);
};
export default rootReducers;