import React, { useEffect } from 'react';
import Footer from '../../components/Footer/Footer';
import CamagotchiHeader from '../../components/ChamagotchiHeader/ChamagotchiHeader';
import ChamagotchiIntro from '../../components/ChamagotchiIntro/ChamagotchiIntro';
import IconsHeader from '../../components/IconsHeader/IconsHeader';
import PickPetAlt from '../../components/PickPetAlt/PickPetAlt';
import ChamagotchiInfo from '../../components/ChamagotchiInfo/ChamagotchiInfo';
import './Chamagotchi.css';

function Chamagotchi({ setActivePage }) {

    useEffect(() => {
        setActivePage('chamagotchi');
        return () => {
            setActivePage('');
        }
    }, []);
    
    return (
        <div>
            <CamagotchiHeader />
            <IconsHeader />
            <div className="app_container bg_white d-flex flex-column text-left">
                <ChamagotchiIntro />
                <ChamagotchiInfo />
            </div>
            <PickPetAlt setActivePage={setActivePage}/>
            <Footer />
        </div>
    )
}

export default Chamagotchi
