export const LOGIN_USER_BY_ADDRESS = 'LOGIN_USER_BY_ADDRESS';
export const LOGIN_USER_BY_ADDRESS_SUCCESS = 'LOGIN_USER_BY_ADDRESS_SUCCESS';
export const LOGIN_USER_BY_ADDRESS_FAILED = 'LOGIN_USER_BY_ADDRESS_FAILED';
export const LOGIN_USER_BY_USERNAME = 'LOGIN_USER_BY_USERNAME';
export const LOGIN_USER_BY_USERNAME_SUCCESS = 'LOGIN_USER_BY_USERNAME_SUCCESS';
export const LOGIN_USER_BY_USERNAME_FAILED = 'LOGIN_USER_BY_USERNAME_FAILED';
export const GET_TOKEN_BY_USER = 'GET_TOKEN_BY_USER';
export const GET_TOKEN_BY_USER_SUCCESS = 'GET_TOKEN_BY_USER_SUCCESS';
export const GET_TOKEN_BY_USER_FAILED = 'GET_TOKEN_BY_USER_FAILED';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILED = 'UPDATE_PROFILE_FAILED';
export const GET_USER_DETAIL_BY_ADDRESS = 'GET_USER_DETAIL_BY_ADDRESS';
export const GET_USER_DETAIL_BY_ADDRESS_SUCCESS = 'GET_USER_DETAIL_BY_ADDRESS_SUCCESS';
export const GET_USER_DETAIL_BY_ADDRESS_FAILED = 'GET_USER_DETAIL_BY_ADDRESS_FAILED';
export const UPLOAD_PROFILE_IMAGE = 'UPLOAD_PROFILE_IMAGE';
export const UPLOAD_PROFILE_IMAGE_SUCCESS = 'UPLOAD_PROFILE_IMAGE_SUCCESS';
export const UPLOAD_PROFILE_IMAGE_FAILED = 'UPLOAD_PROFILE_IMAGE_FAILED';
export const UPLOAD_BANNER_IMAGE = 'UPLOAD_BANNER_IMAGE';
export const UPLOAD_BANNER_IMAGE_SUCCESS = 'UPLOAD_BANNER_IMAGE_SUCCESS';
export const UPLOAD_BANNER_IMAGE_FAILED = 'UPLOAD_BANNER_IMAGE_FAILED';
// chamagotchi
export const FIND_BY_TRAITS_TYPE = 'FIND_BY_TRAITS_TYPE';
export const FIND_BY_TRAITS_TYPE_SUCCESS = 'FIND_BY_TRAITS_TYPE_SUCCESS';
export const FIND_BY_TRAITS_TYPE_FAILED = 'FIND_BY_TRAITS_TYPE_FAILED';
export const CREATE_CHAMAGOTCHI = 'CREATE_CHAMAGOTCHI';
export const CREATE_CHAMAGOTCHI_SUCCESS = 'CREATE_CHAMAGOTCHI_SUCCESS';
export const CREATE_CHAMAGOTCHI_FAILED = 'CREATE_CHAMAGOTCHI_FAILED';
export const FIND_CHAMAGOTCHI_BY_USER = 'FIND_CHAMAGOTCHI_BY_USER';
export const FIND_CHAMAGOTCHI_BY_USER_SUCCESS = 'FIND_CHAMAGOTCHI_BY_USER_SUCCESS';
export const FIND_CHAMAGOTCHI_BY_USER_FAILED = 'FIND_CHAMAGOTCHI_BY_USER_FAILED';
export const CREATE_CHAMAGOTCHI_FEED = 'CREATE_CHAMAGOTCHI_FEED';
export const CREATE_CHAMAGOTCHI_FEED_SUCCESS = 'CREATE_CHAMAGOTCHI_FEED_SUCCESS';
export const CREATE_CHAMAGOTCHI_FEED_FAILED = 'CREATE_CHAMAGOTCHI_FEED_FAILED';
export const ON_AFTER_MINT = 'ON_AFTER_MINT';
export const ON_AFTER_MINT_SUCCESS = 'ON_AFTER_MINT_SUCCESS';
export const ON_AFTER_MINT_FAILED = 'ON_AFTER_MINT_FAILED';
export const ON_AFTER_AIRDROP = 'ON_AFTER_AIRDROP';
export const ON_AFTER_AIRDROP_SUCCESS = 'ON_AFTER_AIRDROP_SUCCESS';
export const ON_AFTER_AIRDROP_FAILED = 'ON_AFTER_AIRDROP_FAILED';
export const GET_CHAMAGOTCHI_DETAILS_BY_ID = 'GET_CHAMAGOTCHI_DETAILS_BY_ID';
export const GET_CHAMAGOTCHI_DETAILS_BY_ID_SUCCESS = 'GET_CHAMAGOTCHI_DETAILS_BY_ID_SUCCESS';
export const GET_CHAMAGOTCHI_DETAILS_BY_ID_FAILED = 'GET_CHAMAGOTCHI_DETAILS_BY_ID_FAILED';
export const GET_LEADER_BOARD_DATA = 'GET_LEADER_BOARD_DATA';
export const GET_LEADER_BOARD_DATA_SUCCESS = 'GET_LEADER_BOARD_DATA_SUCCESS';
export const GET_LEADER_BOARD_DATA_FAILED = 'GET_LEADER_BOARD_DATA_FAILED';
// logout
export const USER_LOGOUT = 'USER_LOGOUT';
export const RESET_CHAMAGOTCHI = 'RESET_CHAMAGOTCHI';
export const RESET_STORE_STATUS = 'RESET_STORE_STATUS';
