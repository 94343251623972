import React, { useEffect, useState } from 'react' ;
import { useDispatch, useSelector } from 'react-redux';
import ChamagotchiHeader from '../../components/ChamagotchiHeader/ChamagotchiHeader';
import Footer from '../../components/Footer/Footer';
import IconsHeader from '../../components/IconsHeader/IconsHeader';
import DesignYourPetMenu from '../../components/DesignYourPetMenu/DesignYourPetMenu';
import Background from '../../components/DesignYourPetAssets/Background/Background';
import BottomFrame from '../../components/DesignYourPetAssets/BottomFrame/BottomFrame';
import Buttons from '../../components/DesignYourPetAssets/Buttons/Buttons';
import Chain from '../../components/DesignYourPetAssets/Chain/Chain';
import Characters from '../../components/DesignYourPetAssets/Characters/Characters';
import HronTail from '../../components/DesignYourPetAssets/HronTail/HronTail';
import ScreenColors from '../../components/DesignYourPetAssets/ScreenColors/ScreenColors';
import ScreenFrames from '../../components/DesignYourPetAssets/ScreenFrames/ScreenFrames';
import TopFrames from '../../components/DesignYourPetAssets/TopFrames/TopFrames';
import DesignYourPetWorkdesk from '../../components/DesignYourPetWorkdesk/DesignYourPetWorkdesk';
import { createChamagotchi, onAfterairdrop, onAfterMint, resetChamagotchi } from '../../redux/actions/chamagotchi';
import { BeatLoader, PacmanLoader } from "react-spinners";
import { useHistory } from 'react-router-dom';
import { utils, BigNumber } from 'ethers';
import {nftContract, signer, provider}  from '../../gochitvenderWeb3';
import {airContract,signerPolygon,providerPolygon,iface} from '../../airdropContract';
import { ethers } from 'ethers';

import './DesignYourPet.css';
import { toast } from 'react-toastify';

function DesignYourPet({ accountNo, setActivePage, chamagotchiIdForEdit, setChamagotchiIdForEdit, airdropTxnId }) {
    const [isMinted, setIsMinted] = useState(false);
    const [isProceed, setIsProceed] = useState(false);
    const [activeOption, setActiveOption] = useState("Background")
    const [selectedCharacter, setSelectedCharacter] = useState("");
    const [selectedTopFrame, setSelectedTopFrame] = useState("");
    const [selectedBottomFrame, setSelectedBottomFrame] = useState("");
    const [selectedScreenFrame, setSelectedScreenFrame] = useState("");
    const [selectedHornTail, setSelectedHornTail] = useState("");
    const [selectedButtons, setSelectedButtons] = useState("");
    const [selectedChain, setSelectedChain] = useState("");
    const [selectedScreenColor, setSelectedScreenColor] = useState("");
    const [selectedBackground, setSelectedBackground] = useState("");
    const [selectedCharacterId, setSelectedCharacterId] = useState("");
    const [selectedTopFrameId, setSelectedTopFrameId] = useState("");
    const [selectedBottomFrameId, setSelectedBottomFrameId] = useState("");
    const [selectedScreenFrameId, setSelectedScreenFrameId] = useState("");
    const [selectedHornTailId, setSelectedHornTailId] = useState("");
    const [selectedButtonsId, setSelectedButtonsId] = useState("");
    const [selectedChainId, setSelectedChainId] = useState("");
    const [selectedScreenColorId, setSelectedScreenColorId] = useState("");
    const [selectedBackgroundId, setSelectedBackgroundId] = useState("");
    const [chamagotchiId, setChamagotchiId] = useState('');
    const [nftPrice, setNftPrice] = useState(0.05);
    const [chamgotchiTokenId, setChamgotchiTokenId] = useState();
    const [airdropHash, setAirdropHash] = useState();
    const [airdropLoading, setAirdropLoading] = useState(false);
    const [airdroping, setAirdroping] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();
    const creatingChamagotchi = useSelector(state => state.chamagotchi.creatingChamagotchi);
    const chamagotchiCreatedStatus = useSelector(state => state.chamagotchi.chamagotchiCreatedStatus);
    const ChamagotchiDetails = useSelector(state => state.chamagotchi.ChamagotchiDetails);
    const airdropChamagotchiStatus = useSelector(state => state.chamagotchi.airdropChamagotchiStatus);
    const error = useSelector(state => state.chamagotchi.error);
    console.log("airdropTxnId : ", airdropTxnId);
    console.log("chamagotchiIdForEdit : ", chamagotchiIdForEdit);
    const mintNftHandler = async () => {
        try {
            
            // at a later point in Time
           
            let t1 = (1000 * nftPrice);
            let nftPrice1 = (1000000000000000 * t1).toString()

            console.log("Price of the token is 123 : ",nftPrice1);
            const balance = BigNumber.from(nftPrice1.toString(16));
            console.log( "balance...123",balance);
            console.log( "utils...123",utils.formatEther(balance));
            //let nftTxn = await nftContract.activateMint({from :'0xDF11D3a21DCAc501282d5376e0819f9cc5BD7200'});
            // const estimation = await nftContract.estimateGas.purchase();
            // console.log("Estimated gas is ==============",estimation);
            let nftTxn = await nftContract.purchase({
                from:signer.address,
                value: balance
            });

            if(nftTxn && nftTxn.from && nftTxn.hash){
                dispatch(onAfterMint({ accountAddress: nftTxn.from, mintTxnId: nftTxn.hash }))
                setIsMinted(true);
            }
            console.log("Mining... please wait");
            console.log("txnObject : ", nftTxn)
            console.log(`Discount price of token is ============/${nftTxn.hash}`);
           
        } catch (err) {
        console.log(err);
        alert(err.message);
        }
    }
     const FetchingTokenOnwerHandler = async () => {
        try {
            // Read Token ID from frontend or pass it from backend while querying this data. currently I have passed 1
           let nftowner = await airContract.ownerOf(1);
           console.log("Omner of the token is ",nftowner);
          } catch (err) {
        console.log(err);
        }
    }

    const SetTokenURIHandler = async () => {
        try {
            // Read Token ID from frontend or pass it from backend while querying this data. currently I have passed 1
           let nftURI = await airContract.tokenURI(1);
           console.log("Omner of the token is ",nftURI);
          } catch (err) {
        console.log(err);
        }
    }

    const airDropNftHandler = async () => {
        setAirdropLoading(true);
        try {
            const privKey = process.env.REACT_APP_PRIVATE_KEY;
            console.log("Private key received is ",privKey);
            let wallet = new ethers.Wallet(privKey)
            const gasPrice = providerPolygon.getGasPrice()
            let walletSigner = wallet.connect(providerPolygon); // Generating signature using the private keys
            console.log("Wallet Signer is ======================",walletSigner);

            console.log("airContractairContract  airContract Signer is ======================",airContract);
            console.log("Interface  data is ",iface);

            const dataen= iface.encodeFunctionData("airDrop",[[ChamagotchiDetails.accountCode]]); // encoding data to create raw transaction
            
            console.log("Decoded data is ",dataen);

            let gasPriceHex = ethers.utils.hexlify(8000000000);

            //Set max gas limit to 4M
                var gasLimitHex = ethers.utils.hexlify(4000000);
                // Raw Transaction
                var rawTx = {
                    from: process.env.REACT_APP_FROM_ADDRESS,
                    to:'0xBa1C37BC9a06D6c6B0361a8833a1E90B0cC71070',
                    data: dataen,
                    gasLimit: gasLimitHex,
                    gasPrice: gasPriceHex,
                };

            console.log("Raw transaction at line 86 is", rawTx);
            var sendTransactionPromise = walletSigner.sendTransaction(rawTx); // Signing the transaction
            var transactionHash;
            var transactionReceipttx;

            // Sending transaction to blockchain
            await sendTransactionPromise.then(function(tx) {
                    transactionHash =  tx;
                    console.log("Transaction Has received at line 89 is ",tx);                    
                });
            console.log("Transaction Has received at line 89 is ",transactionHash);
            setAirdropHash(transactionHash.hash);
            // Handling transaction receipt to read the logs
            setTimeout(
                async ()=>{
                    await providerPolygon.getTransactionReceipt(transactionHash.hash).then(function(transactionReceipt) {
                        console.log(transactionReceipt);
                        transactionReceipttx = transactionReceipt;

                    });
                    console.log("transactionReceipttx Has received at line 137 is ",transactionReceipttx);

                    // Logic to read event value like token ID minted
                   if (transactionReceipttx){
                       let parsed: LogDescription;
                       parsed = iface.parseLog(transactionReceipttx.logs[0]); // here you can add your own logic to find the correct log
                       const {from, to, tokenID} = parsed.args;
                       console.log("Token ID is ",(parsed.args.tokenId).toNumber());
                       setChamgotchiTokenId((parsed.args.tokenId).toNumber() )
                   }else{
                       setChamgotchiTokenId("NA")
                   }
                   setAirdropLoading(false);
                   setAirdroping(false)
                },10000
            )

        } catch (err) {
        console.log(err);
        }
    }

    useEffect(() => {
        if(chamagotchiCreatedStatus == 200 ) {
            if(!airdropTxnId) {
                airDropNftHandler()
            } else {
                history.push('/dashboard');
                setActivePage('dashboard');
            }
            setSelectedCharacterId(null)
            setSelectedTopFrameId(null)
            setSelectedBottomFrameId(null)
            setSelectedScreenFrameId(null)
            setSelectedHornTailId(null)
            setSelectedButtonsId(null)
            setSelectedChainId(null)
            setSelectedScreenColorId(null)
            setSelectedBackgroundId(null)
            setSelectedCharacter(null)
            setSelectedTopFrame(null)
            setSelectedBottomFrame(null)
            setSelectedScreenFrame(null)
            setSelectedHornTail(null)
            setSelectedButtons(null)
            setSelectedChain(null)
            setSelectedScreenColor(null)
            setSelectedBackground(null)
        }
    }, [chamagotchiCreatedStatus]);

    useEffect(() => {
        if(chamgotchiTokenId && airdropHash) {
            dispatch(onAfterairdrop({tokenId: chamgotchiTokenId, chamagotchiId: chamagotchiId, airdropHash : airdropHash}));
            setChamagotchiIdForEdit('');
        }
        return () => {
            setChamgotchiTokenId('');
        }
    }, [chamgotchiTokenId]);

    useEffect(() => {
        if(airdropChamagotchiStatus === 200 || airdropChamagotchiStatus === 302) {
            history.push('/dashboard');
            setActivePage('dashboard');
        }
        return () => {
            dispatch(resetChamagotchi());
            setChamgotchiTokenId('');
        }
    }, [airdropChamagotchiStatus]);

    useEffect(() => {
        dispatch(resetChamagotchi())
        setActivePage('design-your-pet');
        return () => {
            dispatch(resetChamagotchi());
            setIsMinted(false);
            setChamagotchiIdForEdit('');
            setIsProceed(false);
            setActivePage('');
        }
    }, []);

    // to set id for editing chamagotchi after creating
    useEffect(() => {
        if(chamagotchiIdForEdit) {
            setIsMinted(true);
            setIsProceed(true);
            setChamagotchiId(chamagotchiIdForEdit);
        }
    }, [chamagotchiIdForEdit]);

    return (
        <div className='position-relative'>
            {(!isMinted && !isProceed) ? 
                <div 
                    className="minting_popup_wrapper position-absolute d-flex justify-content-center"
                >
                    <div className="minting_popup bg_white rounded-3 p-5 d-flex flex-column align-items-start">
                            <span className='text-secondary' >
                                Select Holding Type
                            </span>
                            <div className="minting_dropdown d-flex flex-column align-items-start">
                                <div class="form-check form-check-inline cursor-pointer py-1 pt-2" >
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" value={0.05} onClick={() => setNftPrice(0.05)} id="flexRadioDefault1" checked={nftPrice == 0.05}/>
                                    <label class="form-check-label" for="flexRadioDefault1">
                                        Public (0.05 ETH)
                                    </label>
                                    </div>
                                <div class="form-check form-check-inline cursor-pointer py-1" >
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" value={0.035} onClick={() => setNftPrice(0.035)} id="flexRadioDefault2" checked={nftPrice == 0.035}/>
                                    <label class="form-check-label" for="flexRadioDefault2">
                                        Partners (0.035 ETH)
                                    </label>
                                </div>
                                <div class="form-check form-check-inline cursor-pointer py-1 pb-4" >
                                    <input class="form-check-input" type="radio" name="flexRadioDefault" value={0.025} onClick={() => setNftPrice(0.025)} id="flexRadioDefault3" checked={nftPrice == 0.025}/>
                                    <label class="form-check-label" for="flexRadioDefault3">
                                        ChamFam (0.025 ETH)
                                    </label>
                                </div>
                            </div>
                        <button 
                            className='btn btn-primary' 
                            onClick={() => {
                                mintNftHandler();
                            }}
                        >
                            Buy Chamagotchi
                        </button>
                    </div>
                </div>
                : (!isProceed) ?
                <div className="minting_popup_wrapper position-absolute d-flex justify-content-center">
                    <div className="minting_popup bg_white rounded-3 p-5 d-flex flex-column">
                        <span className="minted_popup_text text-left my-1"><b>From: </b>{ChamagotchiDetails && ChamagotchiDetails.accountCode && ChamagotchiDetails.accountCode}</span>
                        <span className="minted_popup_text text-left my-1"><b>Txn Hash: </b>{ChamagotchiDetails && ChamagotchiDetails.mintTxnId && ChamagotchiDetails.mintTxnId}</span>
                        <span className="minted_popup_text text-left my-1 mb-4"><b>Chamagotchi ID: </b>{ChamagotchiDetails && ChamagotchiDetails.chamagotchiId && ChamagotchiDetails.chamagotchiId}</span>
                        {(ChamagotchiDetails && ChamagotchiDetails.accountCode && ChamagotchiDetails.mintTxnId && ChamagotchiDetails.chamagotchiId) ?
                            <button 
                                className='btn btn-primary'
                                onClick={() => {
                                    setIsProceed(true);
                                    setChamagotchiId(ChamagotchiDetails.chamagotchiId)
                                }}
                            >
                                Proceed to design
                            </button>
                            :
                            <button 
                                className='btn btn-primary'
                                onClick={() => {
                                    toast.info( "Creating Your Chamagotchi Token..." , {
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: false,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                    },);
                                }}
                            >
                                Please wait <BeatLoader size={10} color='#fff'/>
                            </button>
                        }
                    </div>
                </div>
                :
                null

            }
            <ChamagotchiHeader />
            <IconsHeader />
            <div className="design_pet_container app_container bg_white p-5 d-flex flex-column justify-content-center align-items-center">
                <h1 className="design_pet_heading text-uppercase">Design your NFT</h1>
                <h5 className="design_pet_text px-2 py-3 px-sm-5 ">Fully customise your chamagotchi! Work your way through the left menu, make sure a check mark appears next to each element. Once all elements have been designed. click Create!</h5>
                <div className="row">
                    <div className="col-5 col-sm-4">
                        <DesignYourPetMenu 
                            selectedCharacter={selectedCharacter}
                            selectedTopFrame={selectedTopFrame}
                            selectedBottomFrame={selectedBottomFrame}
                            selectedScreenFrame={selectedScreenFrame}
                            selectedHornTail={selectedHornTail}
                            selectedButtons={selectedButtons}
                            selectedChain={selectedChain}
                            selectedScreenColor={selectedScreenColor}
                            selectedBackground={selectedBackground}
                            activeOption={activeOption}
                            setActiveOption={setActiveOption}
                        />
                        <div className="pacman_loader w-100 mt-3 mt-sm-5 position-relative">
                            {(creatingChamagotchi || airdropLoading && !airdroping) ?
                                <div className="position-absolute">
                                    <PacmanLoader loading={creatingChamagotchi || airdropLoading && !airdroping} color='#F0AD4E' />
                                </div>
                                :
                                null
                            }
                        </div>
                        <div className="d-flex flex-column mt-2">
                            {(  selectedCharacterId &&
                                selectedTopFrameId &&
                                selectedBottomFrameId &&
                                selectedScreenFrameId &&
                                selectedHornTailId &&
                                selectedButtonsId &&
                                selectedChainId &&
                                selectedScreenColorId &&
                                selectedBackgroundId &&
                                chamagotchiId
                            ) ?
                                (!creatingChamagotchi) ?
                                <div 
                                    className="create_pet_btn" 
                                    onClick={() => {
                                        if(isMinted){
                                            dispatch(createChamagotchi({
                                                accountAddress: accountNo,
                                                traitTypeIds: [
                                                    selectedCharacterId,
                                                    selectedTopFrameId,
                                                    selectedBottomFrameId,
                                                    selectedScreenFrameId,
                                                    selectedHornTailId,
                                                    selectedButtonsId,
                                                    selectedChainId,
                                                    selectedScreenColorId,
                                                    selectedBackgroundId,
                                                ],
                                                chamagotchiId : chamagotchiId
                                            }));
                                            setActiveOption("Background");
                                        }
                                    }}
                                >
                                    {(creatingChamagotchi) ? "creating NFT..." : (airdropLoading && !airdroping) ? "airdroping NFT..." : "create" }
                                </div>
                                : 
                                <div className="create_pet_btn disabled_btn">
                                    {(creatingChamagotchi) ? "creating NFT..." : (airdropLoading && !airdroping) ? "airdroping NFT..." : "create" }
                                </div>
                                :
                                <div className="create_pet_btn disabled_btn" >
                                    {(creatingChamagotchi) ? "creating NFT..." : (airdropLoading && !airdroping) ? "airdroping NFT..." : "create" }
                                </div>
                            }
                            <p className="create_pet_Warn text-secondary px-sm-4 p-0">
                                <span className="text-uppercase">remember:&nbsp;</span>
                                Once your NFT  is created you can no longer  make any changes to it
                            </p>
                            {/* creact default chamagotchi btn */}
                            {/* <h1 className="text-warning mt-4">OR</h1>
                            <div className="create_pet_btn_defalult mt-1"
                                 onClick={() => {
                                     if(isMinted){
                                        airDropNftHandler();
                                        setAirdroping(true)
                                    }
                                 }}>
                                {airdropLoading && airdroping ? <BeatLoader loading={airdropLoading && airdroping} color='#F0AD4E' /> : "Create Default"}
                            </div> */}
                        </div>
                    </div>
                    <div className="col-7 col-sm-8">
                        <DesignYourPetWorkdesk 
                            selectedCharacter={selectedCharacter}
                            selectedTopFrame={selectedTopFrame}
                            selectedBottomFrame={selectedBottomFrame}
                            selectedScreenFrame={selectedScreenFrame}
                            selectedHornTail={selectedHornTail}
                            selectedButtons={selectedButtons}
                            selectedChain={selectedChain}
                            selectedScreenColor={selectedScreenColor}
                            selectedBackground={selectedBackground}
                        />
                        {(() => {
                                switch (activeOption) {
                                case 'character':
                                    return (
                                        <Characters 
                                            selectedCharacter={selectedCharacter} 
                                            setSelectedCharacter={setSelectedCharacter}
                                            setSelectedCharacterId={setSelectedCharacterId}
                                        />
                                        )
                                case 'Top Frame':
                                    return (
                                        <TopFrames 
                                            selectedTopFrame={selectedTopFrame} 
                                            setSelectedTopFrame={setSelectedTopFrame}
                                            setSelectedTopFrameId={setSelectedTopFrameId}
                                        />
                                        )
                                case 'Bottom Frame':
                                    return (
                                        <BottomFrame 
                                            selectedBottomFrame={selectedBottomFrame} 
                                            setSelectedBottomFrame={setSelectedBottomFrame}
                                            setSelectedBottomFrameId={setSelectedBottomFrameId}
                                        />
                                    )
                                case 'Screen Frame':
                                    return (
                                        <ScreenFrames 
                                            selectedScreenFrame={selectedScreenFrame} 
                                            setSelectedScreenFrame={setSelectedScreenFrame}
                                            setSelectedScreenFrameId={setSelectedScreenFrameId}
                                        />
                                        )
                                case 'Horn / Tail':
                                    return (
                                        <HronTail 
                                            selectedHornTail={selectedHornTail} 
                                            setSelectedHornTail={setSelectedHornTail}
                                            setSelectedHornTailId={setSelectedHornTailId}
                                        />
                                        )
                                case 'Buttons':
                                    return (
                                        <Buttons 
                                            selectedButtons={selectedButtons} 
                                            setSelectedButtons={setSelectedButtons}
                                            setSelectedButtonsId={setSelectedButtonsId}
                                        />
                                        )
                                case 'Chain':
                                    return (
                                        <Chain 
                                            selectedChain={selectedChain} 
                                            setSelectedChain={setSelectedChain}
                                            setSelectedChainId={setSelectedChainId}
                                        />
                                        )
                                case 'Screen Color':
                                    return (
                                        <ScreenColors 
                                            selectedScreenColor={selectedScreenColor} 
                                            setSelectedScreenColor={setSelectedScreenColor}
                                            setSelectedScreenColorId={setSelectedScreenColorId}
                                        />
                                        )
                                case 'Background':
                                    return (
                                        <Background 
                                            selectedBackground={selectedBackground} 
                                            setSelectedBackground={setSelectedBackground}
                                            setSelectedBackgroundId={setSelectedBackgroundId}
                                        />
                                    )
                                default:
                                    return (
                                        null
                                    )
                                }
                        })()}
                    </div>
                </div>  
            </div>
            <Footer />
        </div>
    )
}

export default DesignYourPet
